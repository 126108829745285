import { Carousel } from "@mantine/carousel";
import { Image, Card, rem, Container, Title, createStyles, Center, Box, Grid, Blockquote, BackgroundImage, Text, Anchor, Space, Divider, Group, Badge, Button } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';
import headerIntro from '../header-intro.png';
import headerWomen from '../header-women.png';
import bgSkyGold from '../bg-skygold.png';
import bgSkySilver from '../header-intro.png';


import imgSection41 from '../imgSection4-1.png';
import imgSection42 from '../imgSection4-2.png';
import imgSection43 from '../imgSection4-3.png';

import imgSection51 from '../imgSection5-1.png';
import imgSection52 from '../imgSection5-2.png';
import imgSection53 from '../imgSection5-3.png';

import imgKulitKusam from '../imgKulitKusam.png';
import imgJerawat from '../imgJerawat.png';
import imgLemak from '../imgLemak.png';
import imgKulitKendur from '../imgKulitKendur.png';
import imgKetiak from '../imgKetiak.png';

import buttonWhatsappSekarang from '../button-whatsapp-sekarang.png';
import buttonLihatPerawatan from '../button-lihat-perawatan.png';

import promo1 from '../promo-paket1.png';
import promo2 from '../promo-paket2.png';
import promo3 from '../promo-paket3.png';
import promo4 from '../promo-paket4.png';
import promo5 from '../promo-paket5.png';

import location1 from '../location1.png';
import location2 from '../location2.png';
import location3 from '../location3.png';


import { useRef } from "react";
import { CustomFonts } from "../components/CustomFonts";
import ReactGA from "react-ga4";
import { IconChecks, IconMapPinFilled } from "@tabler/icons-react";


const Lokasi = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "Lokasi" });

  const useStyles = createStyles((theme) => ({
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    section3: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section5: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section6: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    titleResponsive: {
      fontFamily: 'Poppins', fontWeight: 700,
      fontSize: '3.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
      },
    },

    titleContainer: {
      position: 'absolute',
      top: '10rem',
      [theme.fn.smallerThan('lg')]: {
        marginBottom: '0rem',
      },
      [theme.fn.smallerThan('md')]: {
        top: '3rem',
      },
      [theme.fn.smallerThan('xs')]: {
        top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
      },
    },

    root: {
      backgroundSize: '1200px',
      backgroundPosition: 'center',
      backgroundRepeat: "no-repeat",
      backgroundImage:
        'url(#)',
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
      minHeight: "500px"
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
        minHeight: '150px',
      },
    },

    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },

    content: {
      paddingTop: `calc(${theme.spacing.xl} * 5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 5)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },

    title: {
      color: 'white',
      fontFamily: 'Poppins',
      fontWeight: 700,
      lineHeight: 1.05,
      maxWidth: 'max-content',
      fontSize: '5.125rem',
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },

    iconLocation: {
      color: '#FCC419',
    }

  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

  return (

    <>
      <BackgroundImage src="https://sozoskinclinic.com/assets/img/banner-location.png">
        <div className={classes.root}>
          <Container size="lg">
            <div className={classes.inner}>
              <div className={classes.content} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <Title className={classes.title} ta="center">
                  Lokasi <br></br> Sozo Clinic
                </Title>
              </div>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <Container>
        <Box mx="auto" c="#d4f4f4" pt="4rem" pb="4rem">
          <Title order={1} ta="center" c="#0035b8" className={classes.titleResponsive}>  </Title>
          <Grid grow>
            <Grid.Col span={4} >
              <Blockquote
                cite={<Text c="#0035b8">
                  Tanjung Duren Raya No. 41D, Jakarta Barat
                </Text>}
                icon={""}
              ><Title order={2} c="#0035b8">
                  Tanjung Duren
                </Title>
              </Blockquote>
              <Blockquote
                cite={<Text c="#0035b8">
                  Jl. Arteri Pd. Indah No.11a, RT.11/RW.9, Kelurahan Pondok Indah, Kec. Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12240
                </Text>}
                icon={""}
              >
                <Title order={2} c="#0035b8">
                  Bekasi
                </Title>
              </Blockquote>
              <Blockquote
                cite={<Text c="#0035b8">
                  Jl. Bintaro Utama V Blok EA 2, Bintaro Jaya No.27, Pd. Ranji, Kec. Ciputat Timur, Tangerang Selatan
                </Text>}
                icon={""}
              ><Title order={2} c="#0035b8">
                  Bintaro
                </Title>
              </Blockquote>
              <Blockquote
                cite={<Text c="#0035b8">
                  Jl. Cinere Raya No.10-9, Cinere, Kec. Cinere Kota Depok, Jawa Barat 16514
                </Text>}
                icon={""}
              ><Title order={2} c="#0035b8">
                  Cinere
                </Title>
              </Blockquote>
            </Grid.Col>
            <Grid.Col span={4}>
              <Blockquote
                cite={<Text c="#0035b8">
                  Lippo Karawaci, Supermall Karawaci, Jalan Jl. Boulevard Diponegoro No.105, Bencongan Indah, Kec. Klp. Dua, Kabupaten Tangerang, Banten 15811
                </Text>}
                icon={""}
              >
                <Title order={2} c="#0035b8">
                  Karawaci
                </Title>
              </Blockquote>
              <Blockquote
                cite={<Text c="#0035b8">
                  Jl. Pahlawan Revolusi No.2, RW.4, Pd. Bambu, Kec. Duren Sawit, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13430
                </Text>}
                icon={""}
              >
                <Title order={2} c="#0035b8">
                  Pondok Bambu
                </Title>
              </Blockquote>
              <Blockquote
                cite={<Text c="#0035b8">
                  Kec, Ruko Arcade, Jl. Pantai Indah Utara 2, RT.2/RW.7, Kapuk Muara, Kec. Penjaringan, Jkt Utara, Daerah Khusus Ibukota Jakarta 14460 (Sebelah Angke)
                </Text>}
                icon={""}
              >
                <Title order={2} c="#0035b8">
                  PIK
                </Title>
              </Blockquote>
            </Grid.Col>
            <Grid.Col span={4}>
              <Blockquote
                cite={<Text c="#0035b8">
                  Jl. Arteri Pd. Indah No.11a, RT.11/RW.9, Kelurahan Pondok Indah, Kec. Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12240
                </Text>}
                icon={""}
              >
                <Title order={2} c="#0035b8">
                  Arteri
                </Title>
              </Blockquote>
              <Blockquote
                cite={<Text c="#0035b8">
                  Bendungan Hilir No.7, RT.11/RW.3, Bend. Hilir, Kecamatan Tanah Abang, Jakarta, Daerah Khusus Ibukota Jakarta 10210
                </Text>}
                icon={""}
              >
                <Title order={2} c="#0035b8">
                  Bendungan Hilir
                </Title>
              </Blockquote>
              <Blockquote
                cite={<Text c="#0035b8">
                  Lippo Karawaci, Supermall Karawaci, Jalan Jl. Boulevard Diponegoro No.105, Bencongan Indah, Kec. Klp. Dua, Kabupaten Tangerang, Banten 15811
                </Text>}
                icon={""}
              >
                <Title order={2} c="#0035b8">
                  Supermal Karawaci
                </Title>
              </Blockquote>
              <Blockquote
                cite={<Text c="#0035b8">
                  Jl. Iskandar Muda no.23
                </Text>}
                icon={""}
              >
                <Title order={2} c="#0035b8">
                  Medan
                </Title>
              </Blockquote>
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={12} className={classes.gridMobileLocation}>
              <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                <Card.Section >
                  <Carousel
                    withIndicators
                    slideSize="33.333333%"
                    slideGap="md"
                    loop
                    align="start"
                    slidesToScroll={3}
                  >
                    <Carousel.Slide><Image src={location1} /></Carousel.Slide>
                    <Carousel.Slide><Image src={location2} /></Carousel.Slide>
                    <Carousel.Slide><Image src={location3} /></Carousel.Slide>
                  </Carousel>
                </Card.Section>
              </Card>
            </Grid.Col>
          </Grid>
        </Box>

      </Container>


      <CustomFonts />
    </>
  );
};

export default Lokasi;

