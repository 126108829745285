import { Carousel } from "@mantine/carousel";
import { Image, Card, rem, Container, Title, createStyles, Center, Box, Grid, Blockquote, BackgroundImage, Text, Anchor, Space, Divider, Group, Badge, Button, SimpleGrid, List, Table, Flex } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';



import { useRef } from "react";
import ReactGA from "react-ga4";
import { CustomFonts } from "../../../components/CustomFonts";
import logoSozoWhite from '../../../sozo-white.png';
import { Icon360, IconAdjustments, IconStar } from "@tabler/icons-react";


const Mesotherapy = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "Mesotherapy" });

  const useStyles = createStyles((theme) => ({
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    section3: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section5: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section6: {
      // backgroundColor: '#0033b5',
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      minHeight: '350px',
      [theme.fn.smallerThan('lg')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        minHeight: '180px',
      },
    },

    titleResponsive: {
      fontFamily: 'Gilroy', fontWeight: 700,
      fontSize: '3.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
      },
    },

    titleContainer: {
      position: 'absolute',
      top: '10rem',
      [theme.fn.smallerThan('lg')]: {
        marginBottom: '0rem',
      },
      [theme.fn.smallerThan('md')]: {
        top: '3rem',
      },
      [theme.fn.smallerThan('xs')]: {
        top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
      },
    },

    root: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
      },
    },

    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },

    content: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },

    title: {
      color: '#0035b8',
      lineHeight: 1.05,
      fontSize: '2.7rem',
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },

    iconLocation: {
      color: '#FCC419',
    },

    blueBox: {
      textAlign: 'center',
      padding: theme.spacing.xl,
      borderRadius: theme.radius.xl,
      border: '2px solid #0035b7',
      cursor: 'pointer',
    },

    goldButton: {
      backgroundColor: '#0034b0',
      color: 'white'
    }

  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

  return (

    <>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/mesotherapy-banner.png"
        radius="sm"
      >
        <Container mx="auto" mt="3rem" mb="3rem" className={classes.section6} >
          <Flex
            gap="xl"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/mesotherapy-icon1.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/mesotherapy-icon2.png" alt="Random image" />
          </Flex>
          <Image
            maw={'8rem'}
            src={logoSozoWhite}
            pt="20px"
          />
          <Title className={classes.title} c="white" pt="20px">
            Slimming<br></br> Mesotherapy
          </Title>
          <Divider size="md" mt="1rem" maw={100} />
          <Space h="md" />
        </Container>
      </BackgroundImage>
      <Box mx="auto" mt="3rem" mb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Text c="#0035b7" ta="left" fz="md" style={{ fontSize: '20px' }}>Slimming Mesotherapy SOZO Clinic adalah perawatan yang bertujuan untuk menghilangkan lemak berlebih di area tubuh tertentu, seperti kulit dagu, lengan, perut, atau paha. Treatment ini cocok untuk kamu yang ingin mengurangi berat badan, mengurangi kadar lemak dalam tubuh, dan selulit.</Text></div>
          </SimpleGrid>
        </Container>
      </Box>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-blueleft.png"
      >
        <Box mx="auto" pt="3rem" pb="3rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid align="center">
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <Title className={classes.title} c="white" style={{ fontSize: '4rem;' }} ta="left">Manfaat<br></br> Meso <br></br>Slimming<br></br> Treatment</Title>
                      </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <List ta="left" c="#0035b7" style={{ fontSize: '20px' }} type="ordered">
                          <List.Item>Mengencangkan kulit wajah dengan menghilangkan lemak di bagian-bagian tertentu</List.Item>
                          <List.Item>Memudarkan kerutan dan garis halus</List.Item>
                          <List.Item>Membentuk curves wajah, terutama di bagian rahang</List.Item>
                          <List.Item>Menghilangkan lemak yang berlebih di bagian tubuh seperti lengan, perut, paha, kaki, dan pinggul.</List.Item>
                        </List>
                      </Box>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>

      <Box mx="auto" mt="3rem" mb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Title className={classes.title} ta="center" c="#0035b7">Pilih Treatment sesuai Kebutuhanmu!</Title>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid align="center">
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left" c="#0035b7" >Meso Slim Body</Title>
                    <Text c="#0035b7" ta="left">Meso Slim Body SOZO Clinic mampu menghilangkan lemak secara aman dan efektif karena terbuat dari bahan baku berkualitas tinggi. Produk ini juga dapat meningkatkan metabolisme dan meningkatkan energi tubuhmu.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='1rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi45.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/mesotherapy-asset11.png" alt="Random image" />
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid align="center">
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/mesotherapy-asset13.png" alt="Random image" />
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left" c="#0035b7" >Meso Face</Title>
                    <Text c="#0035b7" ta="left">Injeksi Meso Face adalah perawatan yang dirancang untuk membentuk curve wajah dengan menargetkan timbunan lemak pada bagian-bagian tertentu menggunakan teknik mesoterapi (menggunakan jarum kecil).</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='1rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi75.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>
      </Box>

      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-blueright.png"
      >
        <Box mx="auto" pt="3rem" pb="3rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid align="center">
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <List ta="left" c="#0035b7" style={{ fontSize: '20px' }} type="ordered">
                          <List.Item>Jangan mengkonsumsi obat penghilang rasa nyeri seperti aspirin dan sejenisnya seminggu sebelum perawatan.</List.Item>
                          <List.Item>Hindari terkena air hingga 1 jam setelah perawatan</List.Item>
                          <List.Item>Jangan gunakan make up, skincare, serum, atau<br></br> obat jerawat hingga 3 hari setelah perawatan.</List.Item>
                        </List>
                      </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <Title className={classes.title} c="white" style={{ fontSize: '4rem;' }} ta="left">Hal-Hal yang Harus Dihindari Sebelum dan Sesudah Treatment</Title>
                      </Box>
                    </Grid.Col>

                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>
      <Container pt="3rem" pb="3rem">
        <SimpleGrid cols={1}>
          <div><Title order={2} ta="center" c="#0035b7"> Before and After Meso SlimmingTreatment </Title></div>
          <div>
            <Box mx="auto" ta="center" >
              <Grid grow gutter="xs">
                <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                  <Box mx="10px">
                    <Image
                      radius="md"
                      src="https://sozoskinclinic.com/assets/img/mesotherapy-asset21.png"
                      style={{ maxWidth: '350px', margin: 'auto' }}
                    />
                  </Box>
                </Grid.Col>
                <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                  <Box mx="10px">
                    <Image
                      radius="md"
                      src="https://sozoskinclinic.com/assets/img/mesotherapy-asset22.png"
                      style={{ maxWidth: '350px', margin: 'auto' }}
                    />
                  </Box>
                </Grid.Col>

              </Grid>
            </Box>
          </div>
        </SimpleGrid>
      </Container>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/footer.png"
      >
        <Box mx="auto" pt="2rem" c="#d4f4f4" className={classes.section6} >
          <Container>
            <Grid grow gutter="xs">
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">
                  <Text ta="left" c="gray.0" style={{ fontSize: '25px' }}>  Konsultasikan permasalahan <br></br>wajah dan kulitmu secara  <br></br>gratis bersama beauty  <br></br>consultant kami untuk  <br></br>mendapatkan rekomendasi  <br></br>treatment terbaik untukmu. </Text>
                </Box>
              </Grid.Col>
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">
                </Box>
              </Grid.Col>
            </Grid>
          </Container>
        </Box>
      </BackgroundImage>

      <Container mt="4rem" mb="4rem" ta="center">
        <SimpleGrid cols={1}>
          <div><Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank"><Button mx="auto" mt="xl" radius="xl" size="lg" className={classes.goldButton}>
            <Title order={2}>Konsultasi sekarang</Title>
          </Button></Anchor></div>
        </SimpleGrid>
      </Container>
      <CustomFonts />
    </>
  );
};

export default Mesotherapy;

