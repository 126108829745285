import { Image, Card, rem, Container, Title, createStyles, Center, Box, Grid, Blockquote, BackgroundImage, Text, Anchor, Space, Divider, Group, Badge, Button } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';

import { useRef } from "react";
import { CustomFonts } from "../components/CustomFonts";
import ReactGA from "react-ga4";
import { IconChecks, IconMapPinFilled } from "@tabler/icons-react";


const Perawatan = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "Perawatan" });

  const useStyles = createStyles((theme) => ({
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    section3: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section5: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section6: {
      paddingTop: rem(50),
      paddingBottom: '1.125rem',
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    titleResponsive: {
      fontFamily: 'Poppins', fontWeight: 700,
      fontSize: '3.125rem',
      paddingBottom: '1.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
      },
    },

    titleContainer: {
      position: 'absolute',
      top: '10rem',
      [theme.fn.smallerThan('lg')]: {
        marginBottom: '0rem',
      },
      [theme.fn.smallerThan('md')]: {
        top: '3rem',
      },
      [theme.fn.smallerThan('xs')]: {
        top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
      },
    },

    root: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
      },
    },

    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },

    content: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },

    title: {
      fontWeight: 900,
      lineHeight: 1.05,
      maxWidth: rem(500),
      fontSize: rem(48),

      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    goldButton: {
      backgroundColor: '#0034b0',
      color: 'white'
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },

    iconLocation: {
      color: '#FCC419',
    }

  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

  return (

    <>
      <Container>
        <Box mx="auto" c="#d4f4f4" mt="3rem" className={classes.section6}>

          <Title order={1} ta="center" c="#0035b8" className={classes.titleResponsive}> Perawatan Wajah </Title>
          <Grid gutter="xs">
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/prp-asset5.png"

                />
              </Box>
              <Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/prp">
                PRP
              </Button>
            </Grid.Col>
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/prp-asset5.png"

                />
              </Box>
              < Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/microneedling">
                Microneedling
              </Button>
            </Grid.Col>
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/laser-asset19.png"
                />
              </Box>
              < Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/laser">
                Laser
              </Button>
            </Grid.Col>
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/ipl-assetperawatan.png"
                />
              </Box>
              < Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/ipl-face">
                IPL Face
              </Button>
            </Grid.Col>
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/facial-asset75.png"

                />
              </Box>
              < Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/facial">
                Facial
              </Button>
            </Grid.Col>
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/iplface-asset19.png"

                />
              </Box>
              < Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/derma-peel">
                Derma Peel
              </Button>
            </Grid.Col>
          </Grid>
        </Box>

        <Box mx="auto" c="#d4f4f4" className={classes.section6}>
          <Title order={1} ta="center" c="#0035b8" className={classes.titleResponsive}> Perawatan Slimming </Title>
          <Grid gutter="xs">
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/radiofrequency-asset3.png"

                />
              </Box>
              <Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/radio-frequency">
                Radiofrequency
              </Button>
            </Grid.Col>
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/mesotherapy-asset16.png"

                />
              </Box>
              < Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/mesotherapy">
                Mesotherapy
              </Button>
            </Grid.Col>
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/hifu-asset9.png"

                />
              </Box>
              < Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/hifu">
                HIFU
              </Button>
            </Grid.Col>
          </Grid>
        </Box>
        <Box mx="auto" c="#d4f4f4" className={classes.section6}>
          <Title order={1} ta="center" c="#0035b8" className={classes.titleResponsive}> Perawatan Injection </Title>
          <Grid gutter="xs">
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/threadlift-asset6.png"

                />
              </Box>
              <Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/threadlift">
                Threadlift
              </Button>
            </Grid.Col>
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/skinbooster-assetperawatan.png"

                />
              </Box>
              < Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/skinbooster">
                Skinbooster
              </Button>
            </Grid.Col>
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/laser-asset19.png"

                />
              </Box>
              < Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/infusion">
                Infusion
              </Button>
            </Grid.Col>
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/filler-asset5.png"

                />
              </Box>
              < Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/filler">
                Filler
              </Button>
            </Grid.Col>
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/botox-asset16.png"

                />
              </Box>
              < Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/botox">
                Botox
              </Button>
            </Grid.Col>
          </Grid>
        </Box>
        <Box mx="auto" c="#d4f4f4" className={classes.section6}>
          <Title order={1} ta="center" c="#0035b8" className={classes.titleResponsive}> Perawatan Lainnya</Title>
          <Grid gutter="xs">
            <Grid.Col xs={4} sm={4} md={4} lg={4} className={classes.gridMobileLocation}>
              <Box mx="10px">
                <Image
                  radius="md"
                  src="https://sozoskinclinic.com/assets/img/hairremoval-asset15.png"
                />
              </Box>
              < Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
                rel="noopener noreferrer"
                href="#/perawatan/hair-removal">
                Hair Removal
              </Button>
            </Grid.Col>
          </Grid>
        </Box>
      </Container>

      <CustomFonts />
    </>
  );
};

export default Perawatan;

