import { Carousel } from "@mantine/carousel";
import { Image, Card, rem, Container, Title, createStyles, Center, Box, Grid, Blockquote, BackgroundImage, Text, Anchor, Space, Divider, Group, Badge, Button } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';

import { useRef } from "react";
import { CustomFonts } from "../components/CustomFonts";
import ReactGA from "react-ga4";
import { IconArrowLeft, IconChecks, IconMapPinFilled } from "@tabler/icons-react";


const Produk = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "Produk" });

  const useStyles = createStyles((theme) => ({
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    section3: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50),
      paddingBottom: rem(50),

      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section5: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section6: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    titleResponsive: {
      fontFamily: 'Poppins', fontWeight: 700,
      fontSize: '3.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
      },
    },

    titleContainer: {
      position: 'absolute',
      top: '10rem',
      [theme.fn.smallerThan('lg')]: {
        marginBottom: '0rem',
      },
      [theme.fn.smallerThan('md')]: {
        top: '3rem',
      },
      [theme.fn.smallerThan('xs')]: {
        top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
      },
    },

    root: {
      backgroundSize: '1200px',
      backgroundPosition: 'center',
      backgroundRepeat: "no-repeat",
      backgroundImage:
        'url(#)',
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
      minHeight: "500px"
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
        minHeight: '150px',
      },
    },

    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },

    content: {
      paddingTop: `calc(${theme.spacing.xl} * 5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 5)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },

    title: {
      color: 'white',
      fontFamily: 'Poppins',
      fontWeight: 700,
      lineHeight: 1.05,
      maxWidth: 'max-content',
      fontSize: rem(50),
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },

    blueButton: {
      backgroundColor: '#0035b7',
    },

    iconLocation: {
      color: '#FCC419',
    }

  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

  return (

    <>
      <BackgroundImage src="https://sozoskinclinic.com/assets/img/header-product.png">
        <div className={classes.root}>
          <Container size="lg">
            <div className={classes.inner}>
              <div className={classes.content} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                <Title className={classes.title} ta="center">
                  Rawat Kulit Wajah <br></br> Dengan SOZO Skincare
                </Title>
              </div>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <Container>
        <Box mx="auto" c="#d4f4f4" mt="4rem" >
          <Title order={1} c="#0035b8" className={classes.titleResponsive} >Rangkaian skincare untuk <br></br>
            lengkapi kebutuhan wajahmu </Title>
          <Carousel
            withIndicators
            slideSize="25%"
            slideGap="md"
            loop
            align="start"
            slidesToScroll={4}
            mt="2rem"
            previousControlIcon={<IconArrowLeft size={16} />}
          >
            <Carousel.Slide >
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section>
                  <Image
                    src="https://sozoskinclinic.com/assets/img/product-1.jpeg"
                    alt="Norway"
                  />
                </Card.Section>
                <Card.Section style={{ backgroundColor: '#0035b7', paddingLeft: '20px' }}>
                  <Text size="lg" c="white" fw={700}>
                    White silk serum
                  </Text>
                </Card.Section>
                <Text weight={500} c="#0035b8" fw={700}>Rp 149.000</Text>
                <Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank">
                  <Button fullWidth mt="md" radius="md" className={classes.blueButton}>
                    Beli Sekarang
                  </Button>
                </Anchor>
              </Card>
            </Carousel.Slide>
            <Carousel.Slide >
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section>
                  <Image
                    src="https://sozoskinclinic.com/assets/img/product-2.jpeg"
                    alt="Norway"
                  />
                </Card.Section>
                <Card.Section style={{ backgroundColor: '#0035b7', paddingLeft: '20px' }}>
                  <Text size="lg" c="white" fw={700}>
                    Goodbye Acne serum
                  </Text>
                </Card.Section>
                <Text weight={500} c="#0035b8" fw={700}>Rp 149.000</Text>
                <Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank">
                  <Button fullWidth mt="md" radius="md" className={classes.blueButton}>
                    Beli Sekarang
                  </Button>
                </Anchor>
              </Card>
            </Carousel.Slide>
            <Carousel.Slide >
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section>
                  <Image
                    src="https://sozoskinclinic.com/assets/img/product-4.jpeg"
                    alt="Norway"
                  />
                </Card.Section>
                <Card.Section style={{ backgroundColor: '#0035b7', paddingLeft: '20px' }}>
                  <Text size="lg" c="white" fw={700}>
                    Luminous silk cream
                  </Text>
                </Card.Section>
                <Text weight={500} c="#0035b8" fw={700}>Rp 99.000</Text>
                <Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank">
                  <Button fullWidth mt="md" radius="md" className={classes.blueButton}>
                    Beli Sekarang
                  </Button>
                </Anchor>
              </Card>
            </Carousel.Slide>
            <Carousel.Slide >
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section>
                  <Image
                    src="https://sozoskinclinic.com/assets/img/product-3.jpeg"
                    alt="Norway"
                  />
                </Card.Section>
                <Card.Section style={{ backgroundColor: '#0035b7', paddingLeft: '20px' }}>
                  <Text size="lg" c="white" fw={700}>
                    Advanced acne cream
                  </Text>
                </Card.Section>
                <Text weight={500} c="#0035b8" fw={700}>Rp 99.000</Text>
                <Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank">
                  <Button fullWidth mt="md" radius="md" className={classes.blueButton}>
                    Beli Sekarang
                  </Button>
                </Anchor>
              </Card>
            </Carousel.Slide>
            <Carousel.Slide >
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section>
                  <Image
                    src="https://sozoskinclinic.com/assets/img/product-5.jpeg"
                    alt="Norway"
                  />
                </Card.Section>
                <Card.Section style={{ backgroundColor: '#0035b7', paddingLeft: '20px' }}>
                  <Text size="lg" c="white" fw={700}>
                    Hydro essence toner
                  </Text>
                </Card.Section>
                <Text weight={500} c="#0035b8" fw={700}>Rp 79.000</Text>
                <Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank">
                  <Button fullWidth mt="md" radius="md" className={classes.blueButton}>
                    Beli Sekarang
                  </Button>
                </Anchor>
              </Card>
            </Carousel.Slide>
            <Carousel.Slide >
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section>
                  <Image
                    src="https://sozoskinclinic.com/assets/img/product-9.jpeg"
                  />
                </Card.Section>
                <Card.Section style={{ backgroundColor: '#0035b7', paddingLeft: '20px' }}>
                  <Text size="lg" c="white" fw={700}>
                    Gentle rice cleanser
                  </Text>
                </Card.Section>
                <Text weight={500} c="#0035b8" fw={700}>Rp 79.000</Text>
                <Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank">
                  <Button fullWidth mt="md" radius="md" className={classes.blueButton}>
                    Beli Sekarang
                  </Button>
                </Anchor>
              </Card>
            </Carousel.Slide>

          </Carousel>
        </Box>
        <Box mx="auto" c="#d4f4f4" className={classes.section4}>
          <Title order={2} c="#0035b8" className={classes.titleResponsive}>Skincare personal sesuai<br></br>
            dengan kebutuhan wajahmu </Title>
          <Text mb="2rem" c="#0035b8" ta="left">Di desain khusus untuk mengatasi permasalahan wajah setiap individu sesuai
            dengan rekomendasi terbaik Dokter</Text>
          <Carousel
            withIndicators
            slideSize="25%"
            slideGap="md"
            loop
            align="start"
            slidesToScroll={4}
            mt="2rem"
          >
            <Carousel.Slide >
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section>
                  <Image
                    src="https://sozoskinclinic.com/assets/img/product-6.jpeg"
                  />
                </Card.Section>
                <Card.Section style={{ backgroundColor: '#0035b7', paddingLeft: '20px' }}>
                  <Text size="lg" c="white" fw={700}>
                    Acne / brightening cream
                  </Text>
                </Card.Section>
                <Text weight={500} c="#0035b8" fw={700}>Rp 99,000</Text>
                <Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank">
                  <Button fullWidth mt="md" radius="md" className={classes.blueButton}>
                    Beli Sekarang
                  </Button>
                </Anchor>
              </Card>
            </Carousel.Slide>
            <Carousel.Slide >
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section>
                  <Image
                    src="https://sozoskinclinic.com/assets/img/product-7.jpeg"
                  />
                </Card.Section>
                <Card.Section style={{ backgroundColor: '#0035b7', paddingLeft: '20px' }}>
                  <Text size="lg" c="white" fw={700}>
                    Acne toner
                  </Text>
                </Card.Section>
                <Text weight={500} c="#0035b8" fw={700}>Rp 79.000</Text>
                <Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank">
                  <Button fullWidth mt="md" radius="md" className={classes.blueButton}>
                    Beli Sekarang
                  </Button>
                </Anchor>
              </Card>
            </Carousel.Slide>
            <Carousel.Slide >
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Card.Section>
                  <Image
                    src="https://sozoskinclinic.com/assets/img/product-8.jpeg"
                  />
                </Card.Section>
                <Card.Section style={{ backgroundColor: '#0035b7', paddingLeft: '20px' }}>
                  <Text size="lg" c="white" fw={700}>
                    Purifying cleanser
                  </Text>
                </Card.Section>
                <Text weight={500} c="#0035b8" fw={700}>Rp 79.000</Text>
                <Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank">
                  <Button fullWidth mt="md" radius="md" className={classes.blueButton}>
                    Beli Sekarang
                  </Button>
                </Anchor>
              </Card>
            </Carousel.Slide>
          </Carousel>
        </Box>

      </Container >

      <CustomFonts />
    </>
  );
};

export default Produk;

