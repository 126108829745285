import { Carousel } from "@mantine/carousel";
import { Image, Card, rem, Container, Title, createStyles, Center, Box, Grid, Blockquote, BackgroundImage, Text, Anchor, Space, Divider, Group, Badge, Button, SimpleGrid, List, Table, Flex } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';



import { useRef } from "react";
import ReactGA from "react-ga4";
import { CustomFonts } from "../../../components/CustomFonts";
import logoSozoWhite from '../../../sozo-white.png';
import { Icon360, IconAdjustments, IconStar } from "@tabler/icons-react";


const Facial = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "Facial" });

  const useStyles = createStyles((theme) => ({
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    section3: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section5: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section6: {
      // backgroundColor: '#0033b5',
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      minHeight: '350px',
      [theme.fn.smallerThan('lg')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        minHeight: '180px',
      },
    },

    titleResponsive: {
      fontFamily: 'Gilroy', fontWeight: 700,
      fontSize: '3.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
      },
    },

    titleContainer: {
      position: 'absolute',
      top: '10rem',
      [theme.fn.smallerThan('lg')]: {
        marginBottom: '0rem',
      },
      [theme.fn.smallerThan('md')]: {
        top: '3rem',
      },
      [theme.fn.smallerThan('xs')]: {
        top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
      },
    },

    root: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
      },
    },

    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },

    content: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },

    title: {
      color: '#0035b8',
      lineHeight: 1.05,
      fontSize: '2.7rem',
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },

    iconLocation: {
      color: '#FCC419',
    },

    blueBox: {
      textAlign: 'center',
      padding: theme.spacing.xl,
      borderRadius: theme.radius.xl,
      border: '2px solid #0035b7',
      cursor: 'pointer',
    },

    goldButton: {
      backgroundColor: '#0034b0',
      color: 'white'
    }

  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

  return (

    <>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/facial-banner.png"
        radius="sm"
      >
        <Container mx="auto" className={classes.section6} >
          <Flex
            gap="xl"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/facial-icon1.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/facial-icon2.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/facial-icon3.png" alt="Random image" />
          </Flex>
          <Flex
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
            mt="lg"
          >
            <Image
              maw={'8rem'}
              src={logoSozoWhite}
              pt="20px"
            />
            <Title className={classes.title} c="white" pt="20px" pl="20px">
              Facial
            </Title>
          </Flex>
          <Divider size="md" mt="3rem" maw={100} />
          <Space h="md" />
          <Text c="gray.0" fw={700}>It’s Time to Invest in Your Skin!</Text>
        </Container>
      </BackgroundImage>

      <Box mx="auto" pt="3rem" pb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Text c="#0035b7" ta="left" fz="md" style={{ fontSize: '20px' }}>Facial wajah adalah prosedur perawatan rutin untuk membersihkan kulit wajahmu dari berbagai kotoran dan komedo. Melalui perawatan facial wajah, kulit menjadi lebih rileks, tampak lebih cerah, segar, dan bebas komedo.<br></br><br></br>  Berbagai pilihan treatment facial wajah hadir di SOZO Clinic yang dikreasikan khusus untuk mengatasi berbagai masalah kulitmu.</Text></div>
          </SimpleGrid>
        </Container>
      </Box>
      <Box mx="auto" mt="3rem" mb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Box mx="auto" ta="center" >
                <Title className={classes.title} ta="center" mt='1rem' mb='1rem' c="#0035b7"> Manfaat Facial Wajah SOZO Clinic </Title>
                <Grid grow gutter="xs">
                  <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                    <Box mx="10px">
                      <List ta="left" c="#0035b7" >
                        <List.Item>Menghilangkan Kotoran<br></br> dan Sel Kulit Mati</List.Item>
                        <List.Item>Mencerahkan Kulit Wajah</List.Item>
                        <List.Item>Wajah Bebas Komedo</List.Item>
                        <List.Item>Mencegah Jerawat <br></br>Datang Kembali</List.Item>
                        <List.Item>Relaksasi</List.Item>
                      </List>
                    </Box>
                  </Grid.Col>
                  <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                    <Box mx="10px">
                      <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/facial-asset38.png" alt="Random image" />
                    </Box>
                  </Grid.Col>
                </Grid>
                <Image mt='3rem' mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/facial-line.png" alt="Random image" />
              </Box>
            </div>
          </SimpleGrid>
        </Container>
      </Box>

      <Box mx="auto" mt="3rem" mb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Title className={classes.title} ta="center" c="#0035b7"> Pilih Treatment Facial Wajahmu! </Title>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left" c="#0035b7" >Mini Facial</Title>
                    <Text c="#0035b7" ta="left" pt="10px">Treatment facial wajah yang cocok untuk<br></br> kamu yang menginginkan relaksasi berupa <br></br>massage sekaligus membersihkan komedo<br></br> yang menumpuk dengan durasi 30 menit.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='0.5rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/facial-asset60.png" alt="Random image" />
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/facial-asset41.png" alt="Random image" />
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left" c="#0035b7" >Acne Clear Facial</Title>
                    <Text c="#0035b7" ta="left" pt="10px">SOZO Clinic Acne Clear Facial menggunakan <br></br>teknologi facial treatment mutakhir yang <br></br>secara efektif mampu membersihkan sisa<br></br> komedo dan kotoran di wajah sekaligus<br></br> membantu mencegah jerawat datang kembali. <br></br><br></br>Kombinasi masker charcoal dan serum pada Acne Clear Facial mampu menyembuhkan jerawat sekaligus memudarkan bekasnya.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='0.5rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                      <Image maw="6.125rem" src="https://sozoskinclinic.com/assets/img/img-kasa.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left" c="#0035b7" >Skin Bright Facial</Title>
                    <Text c="#0035b7" ta="left" pt="10px">Katakan selamat tinggal pada kulit kusam dan berjerawat dengan treatment facial wajah Skin Bright Facial. Manfaat facial wajah ini berupa relaksasi, membersihkan komedo, sekaligus mencerahkan kulit dengan mengkombinasikan Vit C dan serum brightening yang baik untuk kulit wajahmu.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='0.5rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                      <Image maw="6.125rem" src="https://sozoskinclinic.com/assets/img/img-kasa.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/facial-asset40.png" alt="Random image" />
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/facial-asset61.png" alt="Random image" />
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left" c="#0035b7" >SOZO Signature <br></br>Facial</Title>
                    <Text c="#0035b7" ta="left" pt="10px">Rawat kulitmu dengan treatment facial wajah khusus yang telah kami kembangkan untuk memberikanmu pengalaman facial wajah terbaik. Kami menggabungkan treatment yang menenangkan, teknologi terkini, dan masker dengan ingredients terbaik untuk kulitmu.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='0.5rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>
      </Box>

      <Box mx="auto" pt="1rem" pb="1rem" mt="2rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Title className={classes.title} ta="center" c="#0035b7" style={{ fontSize: '60px' }} pb="2rem"> Facial Add-On </Title></div>
            <div>
              <Box mx="auto" ta="center" >
                <Grid justify="center" >
                  <Grid.Col span={6} style={{ maxWidth: '300px' }} mx="auto">
                    <Box mx="10px" ta="center">
                      {/* <Image
                        radius="md"
                        src="https://sozoskinclinic.com/assets/img/facial-asset73.png"

                      /> */}
                    </Box>
                    <Title className={classes.title} ta="center" c="#0035b7"> Extraction</Title>
                    <Text c="#0035b7" ta="center" pt="10px">Extraction adalah treatment yang bertujuan untuk mengangkat komedo yang ada di pori-pori wajah dengan teknik</Text>
                  </Grid.Col>
                  <Grid.Col span={6} style={{ maxWidth: '300px' }} mx="auto">
                    <Box mx="10px">
                      {/* <Image
                        radius="md"
                        src="https://sozoskinclinic.com/assets/img/facial-asset74.png"

                      /> */}
                    </Box>
                    <Title className={classes.title} ta="center" c="#0035b7"> Massage</Title>
                    <Text c="#0035b7" ta="center" pt="10px">Relaksasikan otot wajahmu dengan massage dari therapist terbaik kami. Treatment ini juga bermanfaat untuk melancarkan peredaran darah sekaligus memberikan sensasi menenangkan pada wajahmu.</Text>
                  </Grid.Col>
                </Grid>
                <Grid justify="center" pt="2rem" pb="2rem">
                  <Grid.Col span={6} style={{ maxWidth: '300px' }} mx="auto">
                    <Box mx="10px">
                      {/* <Image
                        radius="md"
                        src="https://sozoskinclinic.com/assets/img/facial-asset75.png"

                      /> */}
                    </Box>
                    <Title className={classes.title} ta="center" c="#0035b7"> Collagen Mask</Title>
                    <Text c="#0035b7" ta="center" pt="10px">Samarkan garis halus dengan menambahkan Collagen Mask ke dalam rangkaian treatmentmu. Manfaat facial wajah dengan Collagen Mask membuat kulit terasa lebih lembab serta cerah merata.</Text>
                  </Grid.Col>
                  <Grid.Col span={6} style={{ maxWidth: '300px' }} mx="auto">
                    <Box mx="10px">
                      {/* <Image
                        radius="md"
                        src="https://sozoskinclinic.com/assets/img/facial-asset76.png"
                      /> */}
                    </Box>
                    <Title className={classes.title} ta="center" c="#0035b7"> Growth Factor Serum</Title>
                    <Text c="#0035b7" ta="center" pt="10px">Growth Factor Serum merupakan protein yang berfungsi melawan penuaan, menjaga kesehatan epidermis kulit, dan mempercepat proses regenerasi sel. Tingkatkan elastisitas dan kekencangan kulitmu dengan add-on treatment facial wajah ini.</Text>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>
      </Box>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/footer.png"
      >
        <Box mx="auto" c="#d4f4f4" className={classes.section6} >
          <Container>
            <Grid grow gutter="xs">
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Divider size="md" mt="3rem" maw={100} />
                <Space h="md" />
                <Box>
                  <Text ta="left" c="gray.0" style={{ fontSize: '25px' }}>  Konsultasikan permasalahan <br></br>wajah dan kulitmu secara  <br></br>gratis bersama beauty  <br></br>consultant kami untuk  <br></br>mendapatkan rekomendasi  <br></br>treatment terbaik untukmu. </Text>
                </Box>
              </Grid.Col>
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">

                </Box>
              </Grid.Col>
            </Grid>
          </Container>
        </Box>
      </BackgroundImage>

      <Container mt="4rem" mb="4rem" ta="center">
        <SimpleGrid cols={1}>
          <div><Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank"><Button mx="auto" mt="xl" radius="xl" size="lg" className={classes.goldButton}>
            <Title order={2}>Konsultasi sekarang</Title>
          </Button></Anchor></div>
        </SimpleGrid>
      </Container>
      <CustomFonts />
    </>
  );
};

export default Facial;

