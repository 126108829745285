import { useRef, useState } from 'react';
import {
  createStyles,
  Header,
  Group,
  Burger,
  Paper,
  Transition,
  rem,
  Image,
  Text,
  Button,
  Anchor,
  Menu,
  Center,
  Container
} from '@mantine/core';
import { useDisclosure, useElementSize } from '@mantine/hooks';
import { Link } from 'react-router-dom';
import mainLogo from '../logo-sozoskin.png';
import buttonReservasiSekarang from '../button-reservasi-sekarang.png';
import { ScrollTo } from "react-scroll-to";
import { IconBrandWhatsapp, IconChevronDown, IconSettings } from '@tabler/icons-react';
import { Dropdown, ButtonToolbar, Navbar, Nav } from 'rsuite';

const HEADER_HEIGHT = rem(60);

const useStyles = createStyles((theme) => ({
  root: {
    // position: 'absolute',
    // backgroundColor: 'transparent',
    // border: '0'
    position: 'fixed',
    top: '0px',
  },

  mainLogo: {
    // position: 'absolute',
    top: '0',
    [theme.fn.smallerThan('xs')]: {
      textAlign: 'left',
      fontSize: 'inherit'
    },
  },

  FormHeader: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',
    backgroundColor: "#f2b92e",
  },

  titleResponsive: {
    fontWeight: 700,
    fontFamily: `Poppins, ${theme.fontFamily}`,
    [theme.fn.smallerThan('xs')]: {
      textAlign: 'center',
      fontSize: 'inherit'
    },

  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',
    backgroundColor: "#132573",
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  dropdownBurger: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 1,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',
    backgroundColor: "gray.0",
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  headerBottom: {
    position: 'relative',
    zIndex: 1,
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  iconHeaderLocation: {
    [theme.fn.smallerThan('sm')]: {
      // display: 'none',
    },
  },

  iconHeaderOpeningHours: {
    width: '10.375rem',
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },


  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  displayOnMobile: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  hideOnMobile: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    fontWeight: 800,
    display: 'block',
    lineHeight: 1,
    padding: `${rem(0)} ${rem(2)}`,
    margin: `${rem(0)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : '#0035b8',
    fontSize: rem(20),
    gap: rem(3),
    '&:hover': {
      // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : "green",
    },

    [theme.fn.smallerThan('sm')]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    '&, &:hover': {
      // backgroundColor: "#fff",
      borderRadius: '0',
      color: "#132573",
      borderBottom: '3px white solid'
    },
  },
  goldButton: {
    backgroundColor: '#0034b0',
    color: 'white'
  },
  linkLabel: {
    marginRight: rem(5),
  },
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1200px',
    margin: 'auto'
  },
}));

interface HeaderResponsiveProps {
  links: { link: string; label: string; links?: { link: string; label: string; links?: { link: string; label: string; }[] }[] }[];
}

export function HeaderResponsive({ links }: HeaderResponsiveProps) {
  const { classes } = useStyles();
  const [opened, { toggle }] = useDisclosure(false);
  const items = links.map((link) => {

    const menuItems = link.links?.map((item) => (

      item.links === undefined ?

        <Menu.Item key={item.link}>
          <a
            href={item.link}
            className={classes.link}
          // onClick={(event) => event.preventDefault()}
          >
            {item.label}
          </a>
        </Menu.Item>
        :
        <Menu position="right-start">
          <Menu.Target >
            <a
              href={item.link}
              className={classes.link}
              style={{ padding: '1rem 1rem' }}
            >
              <span className={classes.linkLabel}>{item.label}</span>
              <IconChevronDown size={rem(12)} stroke={1.5} />
            </a>
          </Menu.Target>
          <Menu.Dropdown style={{ border: 'none', padding: '0', borderRadius: '0' }}>
            {
              item.links?.map((subitem) => (
                <Menu.Item key={subitem.link}>
                  <a
                    href={subitem.link}
                    className={classes.link}
                  >
                    {subitem.label}
                  </a>
                </Menu.Item>

              ))}


          </Menu.Dropdown>
        </Menu >

    ));


    if (menuItems) {
      return (
        <>
          <Menu key={link.label} transitionProps={{ exitDuration: 0 }} withinPortal position="bottom-start">
            <Menu.Target>
              <a
                href={link.link}
                className={classes.link}
                onClick={(event) => event.preventDefault()}
              >
                <span className={classes.linkLabel}>{link.label}</span>
                <IconChevronDown size={rem(12)} stroke={1.5} />
              </a>
            </Menu.Target>
            <Menu.Dropdown style={{ border: 'none', borderBottom: '5px solid black', padding: '0', borderRadius: '0' }}>
              {menuItems}
            </Menu.Dropdown>

          </Menu >

        </>

      );
    }
    return (
      <a
        key={link.label}
        href={link.link}
        className={classes.link}
      // onClick={(event) => event.preventDefault()}
      >
        {link.label}
      </a>
    );
  });


  return (

    <>

      <Header height={HEADER_HEIGHT} sx={{ borderBottom: 0 }}>
        <Container className={classes.inner} fluid>
          <Group>
            <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
            <Link
              to="/" >
              <Image width={200} src={mainLogo} className={classes.mainLogo} />
            </Link>
          </Group>
          <Group spacing={5} className={classes.links}>
            {items}

          </Group>
          <div className={classes.hideOnMobile}>
            <Group spacing={20} className={classes.links}>
              <Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank">
                <Button mx="auto" radius="xl" size="md" className={classes.goldButton}>
                  <IconBrandWhatsapp /> <Text pl="10px">Reservasi Sekarang</Text>
                </Button>
              </Anchor>
              <Group spacing={5} className={classes.links}>
                <Menu trigger="hover" transitionProps={{ exitDuration: 0 }} withinPortal>
                  <Menu.Target>
                    <a
                      href={'https://sozoskinclinic.com/blog/'}
                      className={classes.link}
                    // onClick={(event) => event.preventDefault()}
                    >
                      <Center>
                        <span className={classes.linkLabel}>Blog</span>

                      </Center>
                    </a>
                  </Menu.Target>

                </Menu>
              </Group>

            </Group>
          </div>
          <Transition transition="pop-top-right" duration={200} mounted={opened}>
            {(styles) => (
              <Paper className={classes.dropdownBurger} withBorder style={styles}>
                {items}
              </Paper>
            )}
          </Transition>
        </Container>
      </Header>

    </>
  );
}