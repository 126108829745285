import { Carousel } from "@mantine/carousel";
import { Image, Card, rem, Container, Title, createStyles, Center, Box, Grid, Blockquote, BackgroundImage, Text, Anchor, Space, Divider, Group, Badge, Button, SimpleGrid, List, Table, Flex } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';



import { useRef } from "react";
import ReactGA from "react-ga4";
import { CustomFonts } from "../../../components/CustomFonts";
import logoSozoWhite from '../../../sozo-white.png';


const Botox = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "Botox" });

  const useStyles = createStyles((theme) => ({
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    section3: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section5: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section6: {
      // backgroundColor: '#0033b5',
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      minHeight: '350px',
      [theme.fn.smallerThan('lg')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        minHeight: '180px',
      },
    },

    titleResponsive: {
      fontFamily: 'Gilroy', fontWeight: 700,
      fontSize: '3.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
      },
    },

    titleContainer: {
      position: 'absolute',
      top: '10rem',
      [theme.fn.smallerThan('lg')]: {
        marginBottom: '0rem',
      },
      [theme.fn.smallerThan('md')]: {
        top: '3rem',
      },
      [theme.fn.smallerThan('xs')]: {
        top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
      },
    },

    root: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
      },
    },

    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },

    content: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },

    title: {
      color: '#0035b8',
      lineHeight: 1.05,
      fontSize: '2.7rem',
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },

    iconLocation: {
      color: '#FCC419',
    },

    blueBox: {
      textAlign: 'center',
      padding: theme.spacing.xl,
      borderRadius: theme.radius.xl,
      border: '2px solid #0035b7',
      cursor: 'pointer',
    },

    goldButton: {
      backgroundColor: '#0034b0',
      color: 'white'
    }

  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

  return (

    <>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/botox-banner.png"
        radius="sm"
      >
        <Container mx="auto" className={classes.section6} >
          <Flex
            gap="xl"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/botox-icon1.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/botox-icon2.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/botox-icon3.png" alt="Random image" />
          </Flex>
          <Image
            maw={'8rem'}
            src={logoSozoWhite}
            pt="20px"
          />
          <Title className={classes.title} c="white" pt="20px"> Anti-Wrinkle <br></br>Treatment (Botox) </Title>
          <Divider size="md" mt="1rem" maw={100} />
          <Space h="md" />
        </Container>
      </BackgroundImage>

      <Box mx="auto" pt="3rem" pb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Text c="#0035b7" ta="left" fz="md" style={{ fontSize: '20px' }}>Anti-wrinkle treatment adalah tindakan perawatan kecantikan dengan menyuntikkan cairan Botulinum toxin untuk mengencangkan kulit, menghilangkan kerutan, dan membentuk wajah V shape dengan cara merelaksasikan otot.</Text></div>
          </SimpleGrid>
        </Container>
      </Box>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-blueleft.png"
      >
        <Box mx="auto" pt="3rem" pb="3rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid align="center">
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <Title className={classes.title} c="white" style={{ fontSize: '4rem;' }} ta="left"> Manfaat <br></br>Perawatan <br></br>Anti-Wrinkle </Title>
                      </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <List ta="left" c="#0035b7" style={{ fontSize: '20px' }}>
                          <List.Item>Mengatasi kerutan di area wajah, seperti mata, kening, dan smile line</List.Item>
                          <List.Item>Meniruskan rahang agar menjadi V-shape</List.Item>
                          <List.Item>Menjadikan wajah lebih awet muda</List.Item>
                        </List>
                      </Box>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>

      <Box mx="auto" mt="3rem" mb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Title className={classes.title} ta="center" >Pilih Jenis Perawatanmu!</Title>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid align="center">
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left">Botox Standar 10u</Title>
                    <Text c="#0035b7" ta="left">Botox Standar 10u menggunakan neurotoksin botulin dimurnikan dari Korea yang digunakan dalam pengobatan relaksan otot dan sering disuntikkan ke otot-otot wajah untuk tujuan memperhalus kerutan kulit.</Text><Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='1rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi50.png" alt="Random image" />
                    </Flex>

                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/botox-asset11.png" alt="Random image" />
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid align="center">
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/botox-asset12.png" alt="Random image" />
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left" >Botox Premium 10u</Title>
                    <Text c="#0035b7" ta="left">Menggunakan jenis neurotoksin botulin buatan Jerman, Botox Premium 10u berguna sebagai relaksan untuk otot-otot wajah dengan tujuan menghilangkan kerutan di kulit.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='1rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi50.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>
      </Box>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-grey.png"
        radius="sm"
      >
        <Box mx="auto" pt="3rem" pb="3rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div><Title className={classes.title} ta="center"> Tahap Perawatan <br></br>Anti-Wrinkle Treatment</Title></div>
              <div>
                <Box mx="auto" ta="center" pt="1rem">
                  <Grid grow gutter="xs">
                    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                      <Box mx="10px">
                        <Image
                          radius="md"
                          src="https://sozoskinclinic.com/assets/img/botox-asset15.png"
                        />
                      </Box>
                      <Text c="#0035b7" ta="center" pt="1rem">Sebelum perawatan dimulai, dokter akan melakukan anestesi untuk mencegah munculnya rasa tidak nyaman selama treatment.</Text>
                    </Grid.Col>
                    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                      <Box mx="10px">
                        <Image
                          radius="md"
                          src="https://sozoskinclinic.com/assets/img/botox-asset16.png"
                        />
                      </Box>
                      <Text c="#0035b7" ta="center" pt="1rem">Selanjutnya dokter akan menyuntikkan cairan neurotoksin botulin pada area yang ditargetkan.</Text>
                    </Grid.Col>
                    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                      <Box mx="10px">
                        <Image
                          radius="md"
                          src="https://sozoskinclinic.com/assets/img/botox-asset17.png"
                        />
                      </Box>
                      <Text c="#0035b7" ta="center" pt="1rem">Hasil treatment akan terlihat dalam 2-3 hari setelahnya.</Text>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
        <BackgroundImage
          src="https://sozoskinclinic.com/assets/img/background-blueright.png"
        >
          <Box mx="auto" pt="3rem" pb="3rem">
            <Container mt="1rem">
              <SimpleGrid cols={1}>
                <div>
                  <Box mx="auto" ta="center" >
                    <Grid align="center">
                      <Grid.Col span={6}>
                        <Box mx="10px">
                          <List ta="left" c="#0035b7" style={{ fontSize: '20px' }}>
                            <List.Item>Efektif mengatasi kerutan dan garis halus di wajah</List.Item>
                            <List.Item>Dilakukan oleh terapis dan dokter profesional, sehingga cepat dan minim rasa sakit</List.Item>
                            <List.Item>Tidak memerlukan waktu pemulihan (downtime)</List.Item>
                          </List>
                        </Box>
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <Box mx="10px">
                          <Title className={classes.title} c="white" style={{ fontSize: '4rem;' }} ta="left">Kelebihan <br></br>Treatment<br></br> Anti-Wrinkle</Title>
                        </Box>
                      </Grid.Col>

                    </Grid>
                  </Box>
                </div>
              </SimpleGrid>
            </Container>
          </Box>

        </BackgroundImage>
        <Container pt="3rem" pb="3rem">
          <SimpleGrid cols={1}>
            <div><Title className={classes.title} ta="center">Dos and Don’ts</Title></div>
            <div>
              <Box mx="auto" ta="center" >
                <Grid grow gutter="xs">
                  <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                    <Box mx="10px">
                      <Table withBorder withColumnBorders className={classes.blueBox}>
                        <thead>
                          <tr>
                            <th style={{ backgroundColor: '#3483df' }}><Title order={3} ta="center" c="gray.0">Dos</Title></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Posisi wajah tetap tegak sampai minimal 4 jam setelah penyuntikan</Text></td>
                          </tr>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Tidak menggerakkan area yang disuntik sampai minimal 1 jam setelah perawatan</Text></td>
                          </tr>
                        </tbody>
                      </Table>
                    </Box>
                  </Grid.Col>
                  <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                    <Box mx="10px">
                      <Table withBorder withColumnBorders className={classes.blueBox}>
                        <thead>
                          <tr>
                            <th style={{ backgroundColor: '#0035b7' }}><Title order={3} ta="center" c="gray.0">Don’ts</Title></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Jangan menunduk sampai 4 jam setelah perawatan</Text></td>
                          </tr>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Jangan menyentuh, memijit, atau memencet area suntikan agar cairan tidak menyebar ke area lain</Text></td>
                          </tr>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Hindari olahraga dan paparan sinar matahari langsung hingga kemerahan di wajah menghilang</Text></td>
                          </tr>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Jangan mengonsumsi alkohol, aspirin, dan obat anti-inflamasi lainnya sebelum treatment</Text></td>
                          </tr>
                        </tbody>
                      </Table>
                    </Box>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
            <div><Title className={classes.title} ta="center" c="#0035b7"> Before and After <br></br>Skinbooster Treatment </Title></div>
            <div>
              <Box mx="auto" ta="center" pt="1rem" pb="1rem" mt="2rem" mb="2rem">
                <Grid grow gutter="xs">
                  <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                    <Box mx="10px">
                      <Image
                        radius="md"
                        src="https://sozoskinclinic.com/assets/img/botox-asset22.png"
                        style={{ maxWidth: '350px', margin: 'auto' }}
                      />
                    </Box>
                  </Grid.Col>
                  <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                    <Box mx="10px">
                      <Image
                        radius="md"
                        src="https://sozoskinclinic.com/assets/img/botox-asset23.png"
                        style={{ maxWidth: '350px', margin: 'auto' }}
                      />
                    </Box>
                  </Grid.Col>

                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>

      </BackgroundImage>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/footer.png"
      >
        <Box mx="auto" c="#d4f4f4" className={classes.section6} >
          <Container>
            <Grid grow gutter="xs">
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">
                  <Text ta="left" c="gray.0" style={{ fontSize: '25px' }}>  Konsultasikan permasalahan <br></br>wajah dan kulitmu secara  <br></br>gratis bersama beauty  <br></br>consultant kami untuk  <br></br>mendapatkan rekomendasi  <br></br>treatment terbaik untukmu. </Text>
                </Box>
              </Grid.Col>
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">

                </Box>
              </Grid.Col>
            </Grid>
          </Container>
        </Box>
      </BackgroundImage>

      <Container mt="4rem" mb="4rem" ta="center">
        <SimpleGrid cols={1}>
          <div><Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank"><Button mx="auto" mt="xl" radius="xl" size="lg" className={classes.goldButton}>
            <Title order={2}>Konsultasi sekarang</Title>
          </Button></Anchor></div>
        </SimpleGrid>
      </Container>
      <CustomFonts />
    </>
  );
};

export default Botox;

