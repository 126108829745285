import { Carousel } from "@mantine/carousel";
import { Image, Card, rem, Container, Title, createStyles, Center, Box, Grid, Blockquote, BackgroundImage, Text, Anchor, Space, Divider, Group, Badge, Button, SimpleGrid, List, Table, Flex } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';



import { useRef } from "react";
import ReactGA from "react-ga4";
import { CustomFonts } from "../../../components/CustomFonts";
import logoSozoWhite from '../../../sozo-white.png';


const Microneedling = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "Microneedling" });

  const useStyles = createStyles((theme) => ({
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    section3: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section5: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section6: {
      // backgroundColor: '#0033b5',
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      minHeight: '350px',
      [theme.fn.smallerThan('lg')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        minHeight: '180px',
      },
    },

    titleResponsive: {
      fontFamily: 'Gilroy', fontWeight: 700,
      fontSize: '3.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
      },
    },

    titleContainer: {
      position: 'absolute',
      top: '10rem',
      [theme.fn.smallerThan('lg')]: {
        marginBottom: '0rem',
      },
      [theme.fn.smallerThan('md')]: {
        top: '3rem',
      },
      [theme.fn.smallerThan('xs')]: {
        top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
      },
    },

    root: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
      },
    },

    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },

    content: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },

    title: {
      fontWeight: 900,
      lineHeight: 1.05,
      maxWidth: rem(500),
      fontSize: rem(48),

      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },

    iconLocation: {
      color: '#FCC419',
    },

    blueBox: {
      textAlign: 'center',
      padding: theme.spacing.xl,
      borderRadius: theme.radius.xl,
      border: '2px solid #0035b7',
      cursor: 'pointer',
    },

    goldButton: {
      backgroundColor: '#0034b0',
      color: 'white'
    }

  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

  return (

    <>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/microneedling-banner.png"
        radius="sm"
      >
        <Box mx="auto" mt="3rem" className={classes.section6} >
          <Flex
            gap="xl"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/microneedling-icon1.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/microneedling-icon2.png" alt="Random image" />
          </Flex>
          <Flex
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
            mt="lg"
          >
            <Image
              maw={'6.125rem'}
              radius="md"
              src={logoSozoWhite}
              mr='lg'
            />
            <Title order={1} ta="left" c="gray.0"> Microneedling Treatment </Title>
          </Flex>
          <Divider size="md" mt="1rem" maw={100} />
          <Space h="md" />
        </Box>
      </BackgroundImage>

      <Box mx="auto" pt="3rem" pb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Text c="#0035b7" ta="left">Microneedling adalah prosedur kecantikan yang dilakukan dengan jarum kecil steril yang ditusukkan ke dalam kulit untuk memicu peningkatan produksi kolagen dan elastin. Perawatan ini bertujuan untuk memperbaiki struktur kulit yang memiliki bopeng atau scar, sehingga kulit akan terlihat lebih muda dan kencang setelah perawatan.</Text></div>
          </SimpleGrid>
        </Container>
      </Box>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-blueleft.png"
      >
        <Box mx="auto" pt="3rem" pb="3rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid align="center">
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <Title order={2} ta="center" c="white"> Manfaat Perawatan Micro needling </Title>
                      </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <List ta="left" c="#0035b7">
                          <List.Item>Memudarkan melasma dan warna kulit gelap akibat kondisi tertentu</List.Item>
                          <List.Item>Mengencangkan kulit kendur</List.Item>
                          <List.Item>Mengurangi bekas luka akibat jerawat, operasi, atau luka bakar</List.Item>
                          <List.Item>Mengurangi garis halus dan kerutan</List.Item>
                          <List.Item>Menyamarkan stretchmark akibat peningkatan atau penurunan berat badan yang drastis.</List.Item>
                        </List>
                      </Box>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>
      <Box mx="auto" mt="3rem" mb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>

            </div>
          </SimpleGrid>
        </Container>
      </Box>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-grey.png"
        radius="sm"
      >
        <Box mx="auto" mt="3rem" mb="3rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div><Title order={2} ta="center" c="#0035b7"> Prosedur Perawatan Microneedling </Title></div>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid grow gutter="xs">
                    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                      <Box mx="10px">
                        <Image
                          radius="md"
                          src="https://sozoskinclinic.com/assets/img/microneedling-asset2.png"
                        />
                      </Box>
                      <Text c="#0035b7" ta="center">Pertama-tama dokter akan memberikan anestesi sebelum prosedur untuk menghindari efek tidak nyaman pada kulit saat prosedur berlangsung</Text>
                    </Grid.Col>
                    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                      <Box mx="10px">
                        <Image
                          radius="md"
                          src="https://sozoskinclinic.com/assets/img/microneedling-asset3.png"
                        />
                      </Box>
                      <Text c="#0035b7" ta="center">Dokter akan mulai melakukan tindakan dengan menusukkan jarum kecil ke dalam lapisan kulit</Text>
                    </Grid.Col>
                    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                      <Box mx="10px">
                        <Image
                          radius="md"
                          src="https://sozoskinclinic.com/assets/img/microneedling-asset4.png"
                        />
                      </Box>
                      <Text c="#0035b7" ta="center">Selanjutnya, dokter akan menambahkan serum yang berguna dalam meremajakan kulit</Text>
                    </Grid.Col>
                    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                      <Box mx="10px">
                        <Image
                          radius="md"
                          src="https://sozoskinclinic.com/assets/img/microneedling-asset5.png"
                        />
                      </Box>
                      <Text c="#0035b7" ta="center">Setelah selesai treatment, dokter akan memberikan krim untuk mengurangi iritasi.</Text>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
        <BackgroundImage
          src="https://sozoskinclinic.com/assets/img/background-blueright.png"
        >
          <Box mx="auto" pt="3rem" pb="3rem">
            <Container mt="1rem">
              <SimpleGrid cols={1}>
                <div>
                  <Box mx="auto" ta="center" >
                    <Grid align="center">
                      <Grid.Col span={6}>
                        <Box mx="10px">
                          <List ta="left" c="#0035b7" type="ordered">
                            <List.Item>Selalu gunakan sunblock atau sunscreen untuk menghindari paparan sinar matahari</List.Item>
                            <List.Item>Bersihkan wajah secara rutin dengan pembersih wajah yang lembut</List.Item>
                            <List.Item>Oleskan pelembap atau krim yang diberikan oleh Dokter</List.Item>
                            <List.Item>Hindari penggunaan produk eksfoliasi</List.Item>
                            <List.Item>Jangan berolahraga atau melakukan aktivitas berat setelah treatment</List.Item>
                          </List>
                        </Box>
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <Box mx="10px">
                          <Title order={2} ta="left" c="white">Hal-hal yang harus diperhatikan setelah treatment</Title>
                        </Box>
                      </Grid.Col>

                    </Grid>
                  </Box>
                </div>
              </SimpleGrid>
            </Container>
          </Box>

        </BackgroundImage>
        <Container pt="3rem" pb="3rem">
          <SimpleGrid cols={1}>
            <div><Title order={2} ta="center" c="#0035b7"> Before and After Microneedling Treatment </Title></div>
            <div>
              <Box mx="auto" ta="center" >
                <Grid grow gutter="xs">
                  <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                    <Box mx="10px">
                      <Image
                        radius="md"
                        src="https://sozoskinclinic.com/assets/img/microneedling-asset6.png"
                      />
                    </Box>
                  </Grid.Col>
                  <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                    <Box mx="10px">
                      <Image
                        radius="md"
                        src="https://sozoskinclinic.com/assets/img/microneedling-asset7.png"
                      />
                    </Box>
                  </Grid.Col>

                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>

      </BackgroundImage>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/footer.png"
      >
        <Box mx="auto" c="#d4f4f4" className={classes.section6} >
          <Container>
            <Grid grow gutter="xs">
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">
                  <Title order={3} ta="left" c="gray.0" > Konsultasikan permasalahan wajah dan kulitmu secara gratis bersama beauty consultant kami untuk mendapatkan rekomendasi treatment terbaik untukmu. </Title>
                </Box>
              </Grid.Col>
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">

                </Box>
              </Grid.Col>
            </Grid>
          </Container>
        </Box>
      </BackgroundImage>

      <Container mt="4rem" mb="4rem" ta="center">
        <SimpleGrid cols={1}>
          <div><Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank"><Button mx="auto" mt="xl" radius="xl" size="lg" className={classes.goldButton}>
            <Title order={2}>Konsultasi sekarang</Title>
          </Button></Anchor></div>
        </SimpleGrid>
      </Container>
      <CustomFonts />
    </>
  );
};

export default Microneedling;

