import { Carousel } from "@mantine/carousel";
import { Image, Card, rem, Container, Title, createStyles, Center, Box, Grid, Text, Anchor, Space, Divider, Button, BackgroundImage } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';

import imgSection51 from '../imgSection5-1.png';
import imgSection52 from '../imgSection5-2.png';
import imgSection53 from '../imgSection5-3.png';

import imgKulitKusam from '../imgKulitKusam.png';
import imgJerawat from '../imgJerawat.png';
import imgLemak from '../imgLemak.png';
import imgKulitKendur from '../imgKulitKendur.png';
import imgKetiak from '../imgKetiak.png';

import location1 from '../location1.png';
import location2 from '../location2.png';
import location3 from '../location3.png';


import { useRef } from "react";
import { CustomFonts } from "../components/CustomFonts";
import ReactGA from "react-ga4";
import { IconBrandWhatsapp, IconChevronRight } from "@tabler/icons-react";
import Popup from "reactjs-popup";


const Home = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });

  const useStyles = createStyles((theme) => ({
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    section3: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section5: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section6: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    titleResponsive: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '3.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
      },
    },

    titleContainer: {
      position: 'absolute',
      top: '10rem',
      [theme.fn.smallerThan('lg')]: {
        marginBottom: '0rem',
      },
      [theme.fn.smallerThan('md')]: {
        top: '3rem',
      },
      [theme.fn.smallerThan('xs')]: {
        top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
      },
    },

    root: {
      backgroundSize: '1200px',
      backgroundPosition: 'center',
      backgroundRepeat: "no-repeat",
      backgroundImage:
        'url(https://sozoskinclinic.com/assets/img/background-intro-1a.png)',
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
      minHeight: "500px",
      WebkitBoxShadow: "0px -50px 30px 0px #fff inset",
      MozBoxShadow: "0px -50px 30px 0px #fff inset",
      boxShadow: "0px -50px 30px 0px #fff inset"
    },


    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
        minHeight: '150px',
      },
    },

    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },

    content: {
      paddingTop: `calc(${theme.spacing.xl} * 5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },

    title: {
      color: '#0035b8',
      fontFamily: 'Poppins',
      fontWeight: 700,
      lineHeight: 1.05,
      maxWidth: 'max-content',
      fontSize: '2.7rem',
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },
    iconLocation: {
      color: '#0035b8',
    },
    goldButton: {
      backgroundColor: '#0034b0',
      color: 'white',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      fontFamily: 'Poppins',
      fontWeight: 600,
    }

  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

  return (

    <>
      <BackgroundImage src="https://sozoskinclinic.com/assets/img/background-intro-2.png">
        <div className={classes.root}>
          <Container >
            <div className={classes.inner}>
              <div className={classes.content}>
                <Title className={classes.title}>
                  Temukan Solusi <br></br> Permasalahan Kulitmu
                </Title>
                <Text pt="0.5rem" pb="0.5rem" c="#0035b8" style={{ fontSize: '18px' }}>Dapatkan Potongan <Text span fw={700} inherit>100rb </Text> Untuk Semua Treatment
                </Text>
                <Space h="xs" />
                <Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank">
                  <Button mx="auto" mt="0.2rem" radius="1rem" size="lg" className={classes.goldButton}>
                    <IconBrandWhatsapp /> <Text pl="10px">Reservasi Sekarang</Text> <IconChevronRight />
                  </Button>
                </Anchor>
              </div>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <Container>
        <Box mx="auto" className={classes.section2}>
          <Container>
            <Center mx="auto" mb="0.5rem" className={classes.marginOnMobile}>
              <Title order={2} c="#0035b8" className={classes.title} ta="center">Kenali Permasalahan Wajah & Kulit </Title>
            </Center>
            <Center mx="auto" className={classes.marginOnMobile}>
              <Text c="#0035b8" ta="center">Sozo Clinic menyediakan berbagai macam perawatan kecantikan terbaik
              </Text>
            </Center>
            <Center mx="auto" mb="2rem" className={classes.marginOnMobile}>
              <Text c="#0035b8" ta="center">yang dirancang khusus untuk memenuhi kebutuhan tiap individu
              </Text>
            </Center>
          </Container>

          <Grid grow>
            <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileServices}>
              <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                <Card.Section >
                  <Center maw={150} h={100} mx="auto" mt="2rem">
                    <Image src={imgKulitKusam} />
                  </Center>
                  <Center mx="auto" mt="2rem">
                    <Text c="#0035b8" ta="center">Kulit Kusam & Flek Hitam </Text>
                  </Center>
                </Card.Section>
              </Card>
            </Grid.Col>
            <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileServices}>
              <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                <Card.Section >
                  <Center maw={150} h={100} mx="auto" mt="2rem">
                    <Image src={imgJerawat} />
                  </Center>
                  <Center mx="auto" mt="2rem">
                    <Text c="#0035b8" ta="center" >Jerawat & Bekas Jerawat </Text>
                  </Center>
                </Card.Section>
              </Card>
            </Grid.Col>
            <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileServices}>
              <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                <Card.Section >
                  <Center maw={150} h={100} mx="auto" mt="2rem">
                    <Image src={imgLemak} />
                  </Center>
                  <Center mx="auto" mt="2rem">
                    <Text c="#0035b8" ta="center">Lemak Berlebih </Text>
                  </Center>
                </Card.Section>
              </Card>
            </Grid.Col>
            <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileServices}>
              <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                <Card.Section >
                  <Center maw={150} h={100} mx="auto" mt="2rem">
                    <Image src={imgKulitKendur} />
                  </Center>
                  <Center mx="auto" mt="2rem">
                    <Text c="#0035b8" ta="center">Kulit Kendur atau Penuaan Kulit</Text>
                  </Center>
                </Card.Section>
              </Card>
            </Grid.Col>
            <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileServices}>
              <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                <Card.Section >
                  <Center maw={150} h={100} mx="auto" mt="2rem">
                    <Image src={imgKetiak} />
                  </Center>
                  <Center mx="auto" mt="2rem">
                    <Text c="#0035b8" ta="center">Rambut yang tidak diinginkan</Text>
                  </Center>
                </Card.Section>
              </Card>
            </Grid.Col>
          </Grid>
          <Center mx="auto" mt="2rem">
            <Anchor href="#/perawatan">
              <Button mx="auto" mt="xl" radius="1rem" size="lg" className={classes.goldButton} style={{
                paddingLeft: '2.5rem',
                paddingRight: '2.5rem'
              }}>
                <Text>Lihat Perawatan</Text>
              </Button>
            </Anchor>
          </Center>
        </Box>
      </Container>
      <Container>
        <Box mx="auto" c="#d4f4f4" className={classes.section3}>
          <Container>
            <Title order={2} c="#0035b8" className={classes.title} style={{ fontSize: '3.5rem' }}>Mengapa Sozo Skin Clinic?
              <Divider size="md" style={{ maxWidth: '100px', marginTop: '1.5rem', paddingBottom: '2.5rem', borderTopColor: '#0035b8' }} /></Title>
            <Grid grow>
              <Grid.Col span={4}>
                <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                  <Card.Section >
                    <Title order={3} c="#0035b8">Tenaga Medis Berlisensi</Title>
                    <Text mt="0.5rem" c="#0035b8">Ditangani langsung oleh Dokter berpengalaman dan profesional
                    </Text>
                  </Card.Section>
                </Card>
              </Grid.Col>
              <Grid.Col span={4}>
                <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                  <Card.Section >
                    <Title order={3} c="#0035b8">Pelayanan Terbaik</Title>
                    <Text mt="0.5rem" c="#0035b8">
                      Suasana klinik nyaman dan bersih dengan staff ramah
                    </Text>
                  </Card.Section>
                </Card>
              </Grid.Col>
              <Grid.Col span={4}>
                <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                  <Card.Section >
                    <Title order={3} c="#0035b8">Harga Terjangkau</Title>
                    <Text mt="0.5rem" c="#0035b8">Perawatan premium dengan harga terbaik
                    </Text>
                  </Card.Section>
                </Card>
              </Grid.Col>
              <Grid.Col span={4}>
                <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                  <Card.Section >
                    <Title order={3} c="#0035b8">Teknologi Canggih</Title>
                    <Text mt="0.5rem" c="#0035b8">
                      Menggunakan mesin-mesin berkualitas tinggi untuk hasil maksima
                    </Text>
                  </Card.Section>
                </Card>
              </Grid.Col>
              <Grid.Col span={4}>
                <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                  <Card.Section >
                    <Title order={3} c="#0035b8">Layanan Konsultasi Dokter Gratis</Title>
                  </Card.Section>
                </Card>
              </Grid.Col>
              <Grid.Col span={4}>
              </Grid.Col>
            </Grid>
            <Grid >
              <Grid.Col span={12}>
                <Carousel
                  slideSize="33.3333333%"
                  slideGap="md"
                  loop
                  align="start"
                  slidesToScroll={3}
                  pt="1rem"
                >
                  <Carousel.Slide>
                    <Image src={'https://sozoskinclinic.com/assets/img/mengapa-sozo-1.png'} />
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <Image src={'https://sozoskinclinic.com/assets/img/mengapa-sozo-2.png'} />
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <Image src={'https://sozoskinclinic.com/assets/img/mengapa-sozo-3.png'} />
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <Image src={'https://sozoskinclinic.com/assets/img/mengapa-sozo-4.png'} />
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <Image src={'https://sozoskinclinic.com/assets/img/mengapa-sozo-5.png'} />
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <Image src={'https://sozoskinclinic.com/assets/img/mengapa-sozo-6.png'} />
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <Image src={'https://sozoskinclinic.com/assets/img/mengapa-sozo-7.png'} />
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <Image src={'https://sozoskinclinic.com/assets/img/mengapa-sozo-8.png'} />
                  </Carousel.Slide>
                  <Carousel.Slide>
                    <Image src={'https://sozoskinclinic.com/assets/img/mengapa-sozo-9.png'} />
                  </Carousel.Slide>
                </Carousel>
              </Grid.Col>
            </Grid>
          </Container>
        </Box>
        <Box mx="auto" c="#d4f4f4" className={classes.section4}>
          <Title order={2} c="#0035b8" className={classes.title}>Promo terbaik bulan ini </Title>
          <Carousel
            slideSize="18.666666666666668%"
            slideGap="md"
            loop
            align="start"
            slidesToScroll={5}
            pt="1rem"
          >
            <Carousel.Slide>
              <Popup
                trigger={
                  <Image src={'https://sozoskinclinic.com/assets/img/promo-1.png'} />
                }
                modal
                nested
              >
                <Image src={'https://sozoskinclinic.com/assets/img/promo-1.png'} maw="500px" />
              </Popup>
            </Carousel.Slide>
            <Carousel.Slide>
              <Popup
                trigger={
                  <Image src={'https://sozoskinclinic.com/assets/img/promo-2.png'} />
                }
                modal
                nested
              >
                <Image src={'https://sozoskinclinic.com/assets/img/promo-2.png'} maw="500px" />
              </Popup>
            </Carousel.Slide>
            <Carousel.Slide>
              <Popup
                trigger={
                  <Image src={'https://sozoskinclinic.com/assets/img/promo-3.png'} />
                }
                modal
                nested
              >
                <Image src={'https://sozoskinclinic.com/assets/img/promo-3.png'} maw="500px" />
              </Popup>
            </Carousel.Slide>
            <Carousel.Slide>
              <Popup
                trigger={
                  <Image src={'https://sozoskinclinic.com/assets/img/promo-4.png'} />
                }
                modal
                nested
              >
                <Image src={'https://sozoskinclinic.com/assets/img/promo-4.png'} maw="500px" />
              </Popup>
            </Carousel.Slide>
            <Carousel.Slide>
              <Popup
                trigger={
                  <Image src={'https://sozoskinclinic.com/assets/img/promo-5.png'} />
                }
                modal
                nested
              >
                <Image src={'https://sozoskinclinic.com/assets/img/promo-5.png'} maw="500px" />
              </Popup>
            </Carousel.Slide>
            <Carousel.Slide>
              <Popup
                trigger={
                  <Image src={'https://sozoskinclinic.com/assets/img/promo-6.png'} />
                }
                modal
                nested
              >
                <Image src={'https://sozoskinclinic.com/assets/img/promo-6.png'} maw="500px" />
              </Popup>
            </Carousel.Slide>
          </Carousel>
        </Box>

      </Container>
      <Container>
        <Box mx="auto" className={classes.section5}>
          <Container>
            <Title order={2} c="#0035b8" className={classes.title} style={{ fontSize: '2.7rem' }}>Lihat Perubahannya
            </Title>
            <Text mt="0.5rem" c="#0035b8">Rasakan perbedaan perawatan wajah dan kulit di Sozo Clinic yang dirancang khusus untuk memenuhi kebutuhan tiap individu</Text>
            <Divider size="md" style={{ maxWidth: '100px', marginTop: '1.5rem', paddingBottom: '1.5rem', borderTopColor: '#0035b8' }} />
            <Grid grow>
              <Grid.Col span={4}>
                <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                  <Card.Section >
                    <Center mx="auto" mt="2rem">
                      <Image src={imgSection51} />
                    </Center>
                  </Card.Section>
                </Card>
              </Grid.Col>
              <Grid.Col span={4}>
                <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                  <Card.Section >
                    <Center mx="auto" mt="2rem">
                      <Image src={imgSection52} />
                    </Center>
                  </Card.Section>
                </Card>
              </Grid.Col>
              <Grid.Col span={4}>
                <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                  <Card.Section >
                    <Center mx="auto" mt="2rem">
                      <Image src={imgSection53} />
                    </Center>
                  </Card.Section>
                </Card>
              </Grid.Col>
            </Grid>
          </Container>
        </Box>
        <Box mx="auto" c="#d4f4f4" className={classes.section6}>
          <Grid >
            <Grid.Col span={6}>
              <Title order={2} c="#0035b8" className={classes.title} style={{ fontSize: '3.5rem' }}> Lokasi <br></br>Sozo Clinic</Title>
              <Text c="#0035b8"> Fasilitas Nyaman & Premium </Text>
              <Anchor href="#/lokasi">
                <Button mx="auto" mt="xl" radius="md" size="lg" className={classes.goldButton} style={{
                  paddingLeft: '3rem',
                  paddingRight: '3rem'
                }}>
                  <Text>Lihat Lokasi</Text>
                </Button>
              </Anchor>
            </Grid.Col>
            <Grid.Col span={6}>
              <Card radius="md" style={{ backgroundColor: 'transparent' }}>
                <Card.Section >
                  <Carousel
                    withIndicators
                    slideSize="100%"
                    loop
                    align="start"
                  >
                    <Carousel.Slide><Image src={location1} /></Carousel.Slide>
                    <Carousel.Slide><Image src={location2} /></Carousel.Slide>
                    <Carousel.Slide><Image src={location3} /></Carousel.Slide>
                  </Carousel>
                </Card.Section>
              </Card>
            </Grid.Col>
          </Grid>
        </Box>
      </Container>
      <CustomFonts />
    </>
  );
};

export default Home;

