import { Global } from '@mantine/core';
import thin from '../fonts/Poppins-Thin.woff2';
import thinItalic from '../fonts/Poppins-ThinItalic.woff2';
import extraLight from '../fonts/Poppins-ExtraLight.woff2';
import extraLightItalic from '../fonts/Poppins-ExtraLightItalic.woff2';
import light from '../fonts/Poppins-Light.woff2';
import lightItalic from '../fonts/Poppins-LightItalic.woff2';
import regular from '../fonts/Poppins-Regular.woff2';
import regularItalic from '../fonts/Poppins-Italic.woff2';
import medium from '../fonts/Poppins-Medium.woff2';
import mediumItalic from '../fonts/Poppins-MediumItalic.woff2';
import semibold from '../fonts/Poppins-SemiBold.woff2';
import semiboldItalic from '../fonts/Poppins-SemiBoldItalic.woff2';
import bold from '../fonts/Poppins-Bold.woff2';
import boldItalic from '../fonts/Poppins-BoldItalic.woff2';
import extrabold from '../fonts/Poppins-ExtraBold.woff2';
import extraboldItalic from '../fonts/Poppins-ExtraBoldItalic.woff2';
import black from '../fonts/Poppins-Black.woff2';
import blackItalic from '../fonts/Poppins-BlackItalic.woff2';

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${thin}') format("woff2")`,
            fontWeight: 100,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${thinItalic}') format("woff2")`,
            fontWeight: 100,
            fontStyle: 'italic',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${extraLight}') format("woff2")`,
            fontWeight: 200,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${extraLightItalic}') format("woff2")`,
            fontWeight: 200,
            fontStyle: 'italic',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${light}') format("woff2")`,
            fontWeight: 300,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${lightItalic}') format("woff2")`,
            fontWeight: 300,
            fontStyle: 'italic',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${regular}') format("woff2")`,
            fontWeight: 400,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${regularItalic}') format("woff2")`,
            fontWeight: 400,
            fontStyle: 'italic',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${medium}') format("woff2")`,
            fontWeight: 500,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${mediumItalic}') format("woff2")`,
            fontWeight: 500,
            fontStyle: 'italic',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${semibold}') format("woff2")`,
            fontWeight: 600,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${semiboldItalic}') format("woff2")`,
            fontWeight: 600,
            fontStyle: 'italic',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${bold}') format("woff2")`,
            fontWeight: 700,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${boldItalic}') format("woff2")`,
            fontWeight: 700,
            fontStyle: 'italic',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${extrabold}') format("woff2")`,
            fontWeight: 800,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${extraboldItalic}') format("woff2")`,
            fontWeight: 800,
            fontStyle: 'italic',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${black}') format("woff2")`,
            fontWeight: 900,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Poppins',
            src: `url('${blackItalic}') format("woff2")`,
            fontWeight: 900,
            fontStyle: 'italic',
          },
        },
      ]}
    />
  );
}