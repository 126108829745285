import { Carousel } from "@mantine/carousel";
import { Image, Card, rem, Container, Title, createStyles, Center, Box, Grid, Blockquote, BackgroundImage, Text, Anchor, Space, Divider, Group, Badge, Button, SimpleGrid, List, Table, Flex } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';



import { useRef } from "react";
import ReactGA from "react-ga4";
import { CustomFonts } from "../../../components/CustomFonts";
import logoSozoWhite from '../../../sozo-white.png';


const Skinbooster = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "Skinbooster" });

  const useStyles = createStyles((theme) => ({
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    section3: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section5: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section6: {
      // backgroundColor: '#0033b5',
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      minHeight: '350px',
      [theme.fn.smallerThan('lg')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        minHeight: '180px',
      },
    },

    titleResponsive: {
      fontFamily: 'Gilroy', fontWeight: 700,
      fontSize: '3.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
      },
    },

    titleContainer: {
      position: 'absolute',
      top: '10rem',
      [theme.fn.smallerThan('lg')]: {
        marginBottom: '0rem',
      },
      [theme.fn.smallerThan('md')]: {
        top: '3rem',
      },
      [theme.fn.smallerThan('xs')]: {
        top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
      },
    },

    root: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
      },
    },

    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },

    content: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },

    title: {
      color: '#0035b8',
      lineHeight: 1.05,
      fontSize: '2.7rem',
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },

    iconLocation: {
      color: '#FCC419',
    },

    blueBox: {
      textAlign: 'center',
      padding: theme.spacing.xl,
      borderRadius: theme.radius.xl,
      border: '2px solid #0035b7',
      cursor: 'pointer',
    },

    goldButton: {
      backgroundColor: '#0034b0',
      color: 'white'
    }

  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

  return (

    <>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/skinbooster-banner.png"
        radius="sm"
      >
        <Container mx="auto" className={classes.section6} >
          <Flex
            gap="xl"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/skinbooster-icon1.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/skinbooster-icon2.png" alt="Random image" />
          </Flex>

          <Image
            maw={'8rem'}
            src={logoSozoWhite}
            pt="20px"
          />
          <Title className={classes.title} c="white" pt="20px"> Skinbooster </Title>

          <Divider size="md" mt="1rem" maw={100} />
          <Space h="md" />
        </Container>
      </BackgroundImage>

      <Box mx="auto" pt="3rem" pb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Text c="#0035b7" ta="left" fz="md" style={{ fontSize: '20px' }}>Skinbooster treatment adalah perawatan kulit dengan teknik micro-injection tepatnya dengan menyuntikkan cairan khusus ke dalam kulit untuk memberikan efek langsung. Jenis serum atau cairan yang disuntikkan tergantung pada kebutuhan kulitmu.</Text></div>
          </SimpleGrid>
        </Container>
      </Box>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-blueleft.png"
      >
        <Box mx="auto" pt="3rem" pb="3rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid align="center">
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <Title className={classes.title} c="white" style={{ fontSize: '5rem;' }} ta="left"> Manfaat <br></br>Skinbooster <br></br>Treatment </Title>
                      </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <List ta="left" c="#0035b7" style={{ fontSize: '20px' }}>
                          <List.Item>Menjadikan kulitmu lebih kencang, sehat, dan cerah</List.Item>
                          <List.Item>Menutrisi kulit wajah dari dalam</List.Item>
                          <List.Item>Memperbaiki jaringan kulit yang rusak akibat hiperpigmentasi atau jerawat</List.Item>
                          <List.Item>Merangsang pertumbuhan kolagen pada kulit</List.Item>
                          <List.Item>Kulit terasa lebih kenyal dan lembap sejak treatment pertama</List.Item>
                          <List.Item>Menyamarkan dan menghilangkan garis halus dan kerutan akibat penuaan</List.Item>
                        </List>
                      </Box>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>

      <Box mx="auto" mt="3rem" mb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Title className={classes.title} ta="center"> Pilih Jenis Skinboostermu </Title>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left">Melasma Injection</Title>
                    <Text c="#0035b7" ta="left">Katakan selamat tinggal pada melasma dan flek hitam pada kulit dengan Melasma Injection SOZO Clinic. Perawatan ini dilakukan dengan menyuntikkan cairan obat khusus untuk menghambat pembentukan melanin penyebab melasma atau flek dengan menggunakan jarum tipis ke dalam lapisan sub-kulit di tengah kulit.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='1rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi45.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/skinbooster-asset8.png" alt="Random image" />
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/skinbooster-asset9.png" alt="Random image" />
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left">DNA Glow <br></br>Skinbooster</Title>
                    <Text c="#0035b7" ta="left">Dapatkan kulit sehat dan terhindar dari penuaan dini dengan DNA Glow Skinbooster Treatment SOZO Clinic. Treatment ini memanfaatkan DNA Salmon yang baik untuk memperbaiki kulit kering, mencerahkan kulit kusam, meningkatkan kolagen, dan melembapkan kulit sehingga kulit menjadi lebih sehat, lembap, cerah, dan bebas tanda-tanda penuaan.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='1rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi60.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left">Glass Skin <br></br>Skinbooster</Title>
                    <Text c="#0035b7" ta="left">Glass Skin Skinbooster adalah perawatan micro-injection dengan menyuntikkan asam hialuronat, asam amino, vitamin, mineral, botoc, dan bahan anti penuaan lainnya ke lapisan mesodermal di bawah kulit. Treatment Glass Skin Skinbooster ini bermanfaat untuk memberikan tampilan kulit jernih seperti kaca atau “glass skin” yang indah dan cocok untuk semua jenis kulit.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='1rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi60.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/skinbooster-asset12.png" alt="Random image" />
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/skinbooster-asset14.png" alt="Random image" />
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left" >Aqua Shine <br></br>Skinbooster</Title>
                    <Text c="#0035b7" ta="left">Aqua Shine Skinbooster adalah treatment peremajaan wajah dengan injeksi larutan mesoterapi dan toksin botulinum ke dalam kulit, sehingga kulit menjadi terhidrasi, tampak sehat, lembut, dan kenyal. Perawatan wajah ini juga dapat mengurangi produksi sebum dan keringat, serta mengecilkan pori-pori sehingga cocok untuk kamu yang memiliki masalah pori-pori besar dan wajah kemerahan.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='1rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi60.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left">SOZO<br></br> Pink Bomb</Title>
                    <Text c="#0035b7" ta="left">SOZO Pink Bomb adalah treatment skin booster yang menggabungkan enam bahan aktif untuk meningkatkan produksi kolagen, sehingga kulit wajah jadi lebih kencang, lembap dari dalam, cerah alami, serta dapat melawan tanda-tanda penuaan.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='1rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi60.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/skinbooster-asset17.png" alt="Random image" />
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>
      </Box >
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-grey.png"
        radius="sm"
      >
        <Box mx="auto" mt="3rem" mb="3rem" pt="1rem" pb="1rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div><Title className={classes.title} ta="center"> Tahap Perawatan Skinbooster <br></br>SOZO Clinic </Title></div>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid grow gutter="xs">
                    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                      <Box mx="10px">
                        <Image
                          radius="md"
                          src="https://sozoskinclinic.com/assets/img/skinbooster-asset19.png"
                        />
                      </Box>
                      <Text c="#0035b7" ta="center">Wajahmu akan dibersihkan terlebih dahulu dengan cleanser</Text>
                    </Grid.Col>
                    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                      <Box mx="10px">
                        <Image
                          radius="md"
                          src="https://sozoskinclinic.com/assets/img/skinbooster-asset20.png"
                        />
                      </Box>
                      <Text c="#0035b7" ta="center">Dokter akan melakukan anastesi pada wajah agar kulit tidak iritasi dan meradang</Text>
                    </Grid.Col>
                    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                      <Box mx="10px">
                        <Image
                          radius="md"
                          src="https://sozoskinclinic.com/assets/img/skinbooster-asset21.png"
                        />
                      </Box>
                      <Text c="#0035b7" ta="center">Setelah anestesi, dokter akan mulai menyuntikkan serum dan cairan medis ke kulitmu</Text>
                    </Grid.Col>
                    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                      <Box mx="10px">
                        <Image
                          radius="md"
                          src="https://sozoskinclinic.com/assets/img/skinbooster-asset22.png"
                        />
                      </Box>
                      <Text c="#0035b7" ta="center">Terakhir, wajah akan dikompres dan dilanjutkan dengan pengaplikasian krim</Text>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
        <BackgroundImage
          src="https://sozoskinclinic.com/assets/img/background-blueright.png"
        >
          <Box mx="auto" pt="3rem" pb="3rem">
            <Container mt="1rem">
              <SimpleGrid cols={1}>
                <div>
                  <Box mx="auto" ta="center" >
                    <Grid align="center">
                      <Grid.Col span={6}>
                        <Box mx="10px">
                          <List ta="left" c="#0035b7" style={{ fontSize: '20px' }} type="ordered">
                            <List.Item>Jangan menyentuh area bekas suntikan hingga 12 jam setelah treatment</List.Item>
                            <List.Item>Jangan menggunakan make up pada area bekas suntikan hingga 12 jam setelah treatment</List.Item>
                            <List.Item>Hindari paparan langsung sinar matahari selama seminggu setelah perawatan</List.Item>
                            <List.Item>Selalu gunakan sunblock atau sunscreen setiap hari</List.Item>
                            <List.Item>Jangan berenang hingga 5-7 hari setelah treatment</List.Item>
                          </List>
                        </Box>
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <Box mx="10px">
                          <Title className={classes.title} c="white" style={{ fontSize: '4rem;' }} ta="right">Dos and Don’ts Sebelum dan Sesudah Treatment</Title>
                        </Box>
                      </Grid.Col>

                    </Grid>
                  </Box>
                </div>
              </SimpleGrid>
            </Container>
          </Box>

        </BackgroundImage>
        <Container pt="3rem" pb="3rem">
          <SimpleGrid cols={1}>
            <div><Title className={classes.title} ta="center"> Before and After <br></br>Skinbooster Treatment </Title></div>
            <div>
              <Box mx="auto" ta="center" >
                <Grid grow gutter="xs">
                  <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                    <Box mx="10px">
                      <Image
                        radius="md"
                        src="https://sozoskinclinic.com/assets/img/skinbooster-asset25.png"
                        style={{ maxWidth: '350px', margin: 'auto' }}
                      />
                    </Box>
                  </Grid.Col>
                  <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                    <Box mx="10px">
                      <Image
                        radius="md"
                        src="https://sozoskinclinic.com/assets/img/skinbooster-asset26.png"
                        style={{ maxWidth: '350px', margin: 'auto' }}
                      />
                    </Box>
                  </Grid.Col>

                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>

      </BackgroundImage>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/footer.png"
      >
        <Box mx="auto" c="#d4f4f4" className={classes.section6} >
          <Container>
            <Grid grow gutter="xs">
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">
                  <Text ta="left" c="gray.0" style={{ fontSize: '25px' }}>  Konsultasikan permasalahan <br></br>wajah dan kulitmu secara  <br></br>gratis bersama beauty  <br></br>consultant kami untuk  <br></br>mendapatkan rekomendasi  <br></br>treatment terbaik untukmu. </Text>
                </Box>
              </Grid.Col>
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">

                </Box>
              </Grid.Col>
            </Grid>
          </Container>
        </Box>
      </BackgroundImage>

      <Container mt="4rem" mb="4rem" ta="center">
        <SimpleGrid cols={1}>
          <div><Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank"><Button mx="auto" mt="xl" radius="xl" size="lg" className={classes.goldButton}>
            <Title order={2}>Konsultasi sekarang</Title>
          </Button></Anchor></div>
        </SimpleGrid>
      </Container>
      <CustomFonts />
    </>
  );
};

export default Skinbooster;

