import './App.css';
import { rem, Container, Group, Image, Text, createStyles, Grid, Dialog, Anchor, MantineProvider } from '@mantine/core';
import { HeaderResponsive } from './components/HeaderResponsive';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import { IconBrandInstagram, IconBrandFacebook, IconBrandTiktok, IconMapPinFilled, IconBrandWhatsapp, IconRecordMail, IconMail } from '@tabler/icons-react';
import mainLogo from './logo-sozoskinwhite.png';
import { CustomFonts } from './components/CustomFonts';
import ReactGA from "react-ga4";
import Produk from './pages/produk';
import Lokasi from './pages/lokasi';
import Perawatan from './pages/perawatan';
import Promo from './pages/promo';
import HairRemoval from './pages/perawatan/hairremoval';
import Prp from './pages/perawatan/perawatan_wajah/prp';
import Microneedling from './pages/perawatan/perawatan_wajah/microneedling';
import Laser from './pages/perawatan/perawatan_wajah/laser';
import IplFace from './pages/perawatan/perawatan_wajah/iplface';
import Facial from './pages/perawatan/perawatan_wajah/facial';
import DermaPeel from './pages/perawatan/perawatan_wajah/dermapeel';
import RadioFrequency from './pages/perawatan/perawatan_slimming/radiofrequency';
import Mesotherapy from './pages/perawatan/perawatan_slimming/mesotherapy';
import Hifu from './pages/perawatan/perawatan_slimming/hifu';
import Injection from './pages/perawatan/injection';
import Skinbooster from './pages/perawatan/perawatan_injection/skinbooster';
import Botox from './pages/perawatan/perawatan_injection/botox';
import Infusion from './pages/perawatan/perawatan_injection/infusion';
import Threadlift from './pages/perawatan/perawatan_injection/threadlift';
import Filler from './pages/perawatan/perawatan_injection/filler';
import { HelmetProvider } from 'react-helmet-async';





function App() {
  // ReactGA.initialize("G-RSSK57X8BT");

  const useStyles = createStyles((theme) => ({
    footer: {
      paddingTop: `calc(${theme.spacing.xl} * 1)`,
      paddingBottom: `calc(${theme.spacing.xl} * 1)`,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : '#0035b7',
      borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#0035b7'
        }`,
      [theme.fn.smallerThan('sm')]: {
        padding: '10px'
      },
    },

    footerTwo: {
      textAlign: 'center',
      paddingTop: `calc(${theme.spacing.xl} * 0.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 0.5)`,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : '#00297c',
      borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : '#00297c'
        }`,
      [theme.fn.smallerThan('sm')]: {
        padding: '10px'
      },
    },
    footerThree: {
      textAlign: 'center',
      paddingTop: `calc(${theme.spacing.xl} * 0.5)`,
      paddingBottom: `calc(${theme.spacing.xl} * 0.5)`,
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
      borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : 'white'
        }`,
      [theme.fn.smallerThan('sm')]: {
        padding: '10px'
      },
    },

    logo: {
      maxWidth: rem(200),
      padding: '10px',
      [theme.fn.smallerThan('sm')]: {
        display: 'none',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    logoMobile: {
      display: 'none',
      [theme.fn.smallerThan('sm')]: {
        display: 'inherit'
      },
    },

    description: {
      marginTop: rem(5),
      [theme.fn.smallerThan('sm')]: {
        marginTop: theme.spacing.xs,
        textAlign: 'center',
      },
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      // [theme.fn.smallerThan('sm')]: {
      //   flexDirection: 'column',
      //   alignItems: 'flex-start',
      // },
    },

    groups: {
      display: 'flex',
      flexWrap: 'wrap',
      [theme.fn.smallerThan('sm')]: {
        paddingTop: '20px',
        maxWidth: 'calc(27% - (0rem - 1rem / 0))',
        // display: 'none',
      },
    },

    wrapper: {
      width: rem(200),
      [theme.fn.smallerThan('sm')]: {
        // maxWidth: 'calc(24% - (1rem - 1rem / 5))',
      },
    },

    link: {
      display: 'block',
      color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[0],
      fontSize: theme.fontSizes.sm,
      paddingTop: rem(3),
      paddingBottom: rem(3),

      '&:hover': {
        textDecoration: 'underline',
      },
    },

    title: {
      fontSize: theme.fontSizes.xs,
      fontWeight: 700,
      fontFamily: `Poppins, ${theme.fontFamily}`,
      marginBottom: `calc(${theme.spacing.xs} / 2)`,
      color: theme.colorScheme === 'dark' ? theme.white : theme.white,
    },

    afterFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing.xl,
      paddingTop: theme.spacing.xl,
      paddingBottom: theme.spacing.xl,

      [theme.fn.smallerThan('sm')]: {
        flexDirection: 'column',
      },
    },

    social: {
      [theme.fn.smallerThan('sm')]: {
        marginTop: theme.spacing.xs,
      },
    },
  }));

  const { classes } = useStyles();

  const links =
    [
      {
        "link": "#",
        "label": "Perawatan",
        "links": [
          {
            "link": "#/perawatan/hair-removal",
            "label": "Permanent Hair Removal",
          },
          {
            "link": "#",
            "label": "Facial and Skin",
            "links": [
              { "link": "#/perawatan/prp", "label": "PRP" },
              { "link": "#/perawatan/laser", "label": "Laser" },
              { "link": "#/perawatan/ipl-face", "label": "IPL Face" },
              { "link": "#/perawatan/facial", "label": "Facial" },
              { "link": "#/perawatan/derma-peel", "label": "Dermapeel" },
            ]
          },
          {
            "link": "#",
            "label": "Cosmetic Injectable",
            "links": [
              { "link": "#/perawatan/skinbooster", "label": "Skinbooster" },
              { "link": "#/perawatan/botox", "label": "Botox" },
              { "link": "#/perawatan/infusion", "label": "Infusion" },
              { "link": "#/perawatan/threadlift", "label": "Threadlift" },
              { "link": "#/perawatan/filler", "label": "Filler" },
            ]
          },
          {
            "link": "#",
            "label": "Slimming",
            "links": [
              { "link": "#/perawatan/radio-frequency", "label": "Radiofrequency" },
              { "link": "#/perawatan/mesotherapy", "label": "Mesotherapy" },
              { "link": "#/perawatan/hifu", "label": "HIFU" },
            ]
          },
        ]
      },
      { "link": "#/produk", "label": "Produk" },
      { "link": "#/lokasi", "label": "Lokasi" },
    ]
    ;

  return (
    <>
      <HelmetProvider>
        <MantineProvider
          theme={{
            fontFamily: 'Poppins',
            fontFamilyMonospace: 'Poppins',
            headings: {
              fontFamily: 'Poppins',
              fontWeight: 700,
            },
            components: {
              Container: {
                defaultProps: {
                  sizes: {
                    xs: 720,
                    sm: 960,
                    md: 1140,
                    lg: 1140,
                    xl: 1320,
                  },
                },
              },
            },
          }}
        >
          <HeaderResponsive links={links}></HeaderResponsive>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/perawatan" element={<Perawatan />} />
            {/* Perawatan */}
            <Route path="/perawatan/hair-removal" element={<HairRemoval />} />

            {/* Perawatan Wajah */}
            <Route path="/perawatan/prp" element={<Prp />} />
            <Route path="/perawatan/microneedling" element={<Microneedling />} />
            <Route path="/perawatan/laser" element={<Laser />} />
            <Route path="/perawatan/ipl-face" element={<IplFace />} />
            <Route path="/perawatan/facial" element={<Facial />} />
            <Route path="/perawatan/derma-peel" element={<DermaPeel />} />

            {/* Perawatan Slimming */}
            <Route path="/perawatan/radio-frequency" element={<RadioFrequency />} />
            <Route path="/perawatan/mesotherapy" element={<Mesotherapy />} />
            <Route path="/perawatan/hifu" element={<Hifu />} />

            {/* Perawatan Injection */}
            <Route path="/perawatan/injection" element={<Injection />} />
            <Route path="/perawatan/skinbooster" element={<Skinbooster />} />
            <Route path="/perawatan/botox" element={<Botox />} />
            <Route path="/perawatan/infusion" element={<Infusion />} />
            <Route path="/perawatan/threadlift" element={<Threadlift />} />
            <Route path="/perawatan/filler" element={<Filler />} />


            {/* Lain-lain */}
            <Route path="/lokasi" element={<Lokasi />} />
            <Route path="/produk" element={<Produk />} />
          </Routes>

          <Dialog opened style={{ maxWidth: '150px', backgroundColor: 'transparent', boxShadow: 'none', padding: 0 }}>
            <Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank">
              <Image src={'https://sozoskinclinic.com/assets/img/reservasi-sekarang.png'} />
            </Anchor>
          </Dialog>
          <footer className={classes.footer}>
            <Container>
              <div className={classes.logoMobile} >
                <Image width={100} src={mainLogo} />
              </div>
              <Grid grow>
                <Grid.Col span={2} className={classes.logo}>
                  <div>
                    <Image width={100} src={mainLogo} />
                  </div>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Text className={classes.title}><IconMapPinFilled size="0.9rem" stroke={2} />  Alamat Head Office</Text>
                  <Text style={{ fontSize: '12px', color: 'white' }} >
                    Jl. Bintaro Utama V Blok EA 2,
                    Bintaro Jaya No.27, Pd. Ranji,
                    Kec. Ciputat Timur, Tanggerang Selatan
                  </Text>
                  <Text className={classes.title} pt="10px"><IconBrandWhatsapp size="0.9rem" stroke={2} /> +6281 2101 00172</Text>
                  <Text style={{ fontSize: '12px', color: 'white' }} ><IconMail size="0.9rem" stroke={2} /> sozoaesthetics@gmail.com</Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Text className={classes.title}>Tentang Sozo Clinic</Text>
                  <Text className={classes.title}>Cabang Sozo Clinic</Text>
                  <Text className={classes.title} mt="20px">Treatment</Text>
                  <Text<'a'>
                    c="gray.0"
                    style={{ fontSize: '12px' }}
                    className={classes.link}
                    component="a"
                    href="#/perawatan"
                    pl="10px"
                  >
                    Facial
                  </Text>
                  <Text<'a'>
                    c="gray.0"
                    style={{ fontSize: '12px' }}
                    className={classes.link}
                    component="a"
                    href="#/perawatan"
                    pl="10px"
                  >
                    Hair Removal
                  </Text>
                  <Text<'a'>
                    c="gray.0"
                    style={{ fontSize: '12px' }}
                    className={classes.link}
                    component="a"
                    href="#/perawatan"
                    pl="10px"
                  >
                    Skinbooster
                  </Text>
                  <Text<'a'>
                    c="gray.0"
                    style={{ fontSize: '12px' }}
                    className={classes.link}
                    component="a"
                    href="#/perawatan"
                    pl="10px"
                  >
                    Slimming
                  </Text>
                  <Text<'a'>
                    c="gray.0"
                    style={{ fontSize: '12px' }}
                    className={classes.link}
                    component="a"
                    href="#/perawatan"
                    pl="10px"
                  >
                    Injection dan Infusion
                  </Text>
                  {/* <Text<'a'>
                  c="gray.0"
                  style={{ fontSize: '12px' }}
                  className={classes.link}
                  component="a"
                  href="#/perawatan"
                  pl="10px"
                >
                  Laser
                </Text>
                <Text<'a'>
                  c="gray.0"
                  style={{ fontSize: '12px' }}
                  className={classes.link}
                  component="a"
                  href="#/perawatan"
                  pl="10px"
                >
                  Microneedling
                </Text>
                <Text<'a'>
                  c="gray.0"
                  style={{ fontSize: '12px' }}
                  className={classes.link}
                  component="a"
                  href="#/perawatan"
                  pl="10px"
                >
                  PRP
                </Text>
              
                <Text<'a'>
                  c="gray.0"
                  style={{ fontSize: '12px' }}
                  className={classes.link}
                  component="a"
                  href="#/perawatan"
                  pl="10px"
                >
                  IPL face
                </Text>
                <Text<'a'>
                  c="gray.0"
                  style={{ fontSize: '12px' }}
                  className={classes.link}
                  component="a"
                  href="#/perawatan"
                  pl="10px"
                >
                  Derma peel
                </Text>
                <Text<'a'>
                  c="gray.0"
                  style={{ fontSize: '12px' }}
                  className={classes.link}
                  component="a"
                  href="#/perawatan"
                  pl="10px"
                >
                  Meso
                </Text>
                <Text<'a'>
                  c="gray.0"
                  style={{ fontSize: '12px' }}
                  className={classes.link}
                  component="a"
                  href="#/perawatan"
                  pl="10px"
                >
                  Filler
                </Text>
                <Text<'a'>
                  c="gray.0"
                  style={{ fontSize: '12px' }}
                  className={classes.link}
                  component="a"
                  href="#/perawatan"
                  pl="10px"
                >
                  Anti-wrinkle
                </Text>
                <Text<'a'>
                  c="gray.0"
                  style={{ fontSize: '12px' }}
                  className={classes.link}
                  component="a"
                  href="#/perawatan"
                  pl="10px"
                >
                  Threadlift
                </Text>
                <Text<'a'>
                  c="gray.0"
                  style={{ fontSize: '12px' }}
                  className={classes.link}
                  component="a"
                  href="#/perawatan"
                  pl="10px"
                >
                </Text> */}
                </Grid.Col>
                <Grid.Col span={2}>
                  <Text className={classes.title}>Waktu Operasional:</Text>
                  <Text style={{ fontSize: '12px', color: 'white' }} >
                    Senin – Sabtu: 09:00 – 19:00
                    <br></br>
                    Minggu: 09.00 – 17.00
                  </Text>
                  <Text>
                  </Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Text className={classes.title}>Kebijakan privasi</Text>
                  <Text className={classes.title} pt="30px">Ikuti Kami</Text>
                  <Group spacing="xs">
                    <Text<'a'>
                      c="gray.0"
                      style={{ fontSize: '12px' }}
                      className={classes.link}
                      component="a"
                      href="https://www.instagram.com/sozo.skinclinic/reels/?hl=en"
                    >
                      <IconBrandInstagram size="0.9rem" stroke={2} />
                    </Text>
                    <Text<'a'>
                      c="gray.0"
                      style={{ fontSize: '12px' }}
                      className={classes.link}
                      component="a"
                      href="https://www.tiktok.com/@sozo.skin.clinic"
                    >
                      <IconBrandTiktok size="0.9rem" stroke={2} />
                    </Text>
                    <Text<'a'>
                      c="gray.0"
                      style={{ fontSize: '12px' }}
                      className={classes.link}
                      component="a"
                      href="https://www.facebook.com/profile.php?id=100075487615952"
                    >
                      <IconBrandFacebook size="0.9rem" stroke={2} />
                    </Text>
                  </Group>
                </Grid.Col>
              </Grid>

            </Container>

          </footer>
          <footer className={classes.footerTwo}>
            <Container>
              <Text className={classes.title}>PT. Pohon Biru Jaya</Text>
              <Text className={classes.title}>Copyright © 2023 SOZO Skin Clinic</Text>
            </Container>
          </footer>
          <footer className={classes.footerThree}>
            <Container>
              <Text className={classes.title} style={{ fontSize: '9px', color: '#d3d3d3' }}>Sozo Skin Clinic merupakan klinik kecantikan di Jakarta, Bekasi, dan Tangerang yang
                menyediakan perawatan wajah dan kulit untuk mengatasi berbagai permasalahan kulit
                seperti komedo, jerawat, bekas jerawat, kulit kusam, flek hitam, kerutan atau garis halus, kulit
                kendur atau penuaan kulit, kulit kering, kulit sensitif, lemak di wajah, dan kulit bergelambir.</Text>
              <Text className={classes.title} style={{ fontSize: '9px', color: '#d3d3d3' }}>Klinik kecantikan terpercaya yang memberikan hasil nyata dengan harga terjangkau dengan
                bantuan dokter ahli yang berpengalaman. Sozo Skin Clinic menyediakan berbagai perawatan
                kecantikan seperti botox wajah, botox rahang, filler dagu, filler bibir, laser bekas jerawat, suntik
                putih, meso, hifu, facial, IPL, hair removal, skinbooster, suntik DNA salmon, threadlift, white
                infusion, dan treatment slimming. Mesin yang digunakan menggunakan teknologi tinggi,
                produk bersertifikasi, dan dokter berlisensi. Teruji klinis, aman, dan efektif.
              </Text>
            </Container>
          </footer>
          <CustomFonts />
        </MantineProvider>
      </HelmetProvider>
    </>
  );
}

export default App;

