import { Carousel } from "@mantine/carousel";
import { Image, Card, rem, Container, Title,  createStyles, Center, Box, Grid, Blockquote, BackgroundImage, Text, Anchor, Space, Divider, Group, Badge, Button } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';


import { useRef } from "react";
import { CustomFonts } from "../../components/CustomFonts";
import ReactGA from "react-ga4";


const Injection = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "Injection" });

  const useStyles = createStyles((theme) => ({ 
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
       paddingLeft: '1rem',
       paddingRight: '1rem',
        },
    },

    section3: {
     paddingTop: rem(50), 
     paddingBottom: rem(50),
     paddingLeft: '2rem',
     paddingRight: '2rem',
     [theme.fn.smallerThan('xs')]: {
      paddingTop: '1.125rem',
      paddingBottom: '1.125rem',
      paddingLeft: '1rem',
       paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50), 
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
       paddingTop: '1.125rem',
       paddingBottom: '1.125rem',
       paddingLeft: '1rem',
       paddingRight: '1rem',
       },
     },
     section5: {
      paddingTop: rem(50), 
      paddingBottom: rem(50),
      paddingLeft: '2rem',
     paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
       paddingTop: '1.125rem',
       paddingBottom: '1.125rem',
       paddingLeft: '1rem',
       paddingRight: '1rem',
       },
     },
     section6: {
      paddingTop: rem(50), 
      paddingBottom: rem(50),
      paddingLeft: '2rem',
     paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
       paddingBottom: '1.125rem',
       paddingLeft: '1rem',
       paddingRight: '1rem',
       },
     },

    titleResponsive: {
      fontFamily: 'Gilroy', fontWeight: 700,
      fontSize: '3.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
        },
    },

    titleContainer: {
     position: 'absolute', 
     top: '10rem',
     [theme.fn.smallerThan('lg')]: {
      marginBottom: '0rem',
    },
     [theme.fn.smallerThan('md')]: {
      top: '3rem',
    },
     [theme.fn.smallerThan('xs')]: {
      top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
        },
    },

    root: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
      },
    },
  
    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },
  
    content: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,
  
      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },
  
    title: {
      fontWeight: 900,
      lineHeight: 1.05,
      maxWidth: rem(500),
      fontSize: rem(48),
  
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    goldButton: {
      backgroundImage: 'linear-gradient(90deg, rgba(209,162,62,1) 0%, rgba(248,223,112,1) 30%, rgba(248,223,112,1) 70%, rgba(226,184,79,1) 100%)',
      color: '#0035b7'
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },

    iconLocation: {
      color: '#FCC419',
    }
    
  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

return (
  
    <>

<Box mx="auto" c="#d4f4f4" mt="2rem" className={classes.section6}>
      <Title order={1} ta="center" c="#0035b8" className={classes.titleResponsive}> Perawatan Injection </Title>
      <Grid grow gutter="xs">
      <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
      <Box mx="10px">
      <Image
        radius="md"
        src="https://images.unsplash.com/photo-1627552245715-77d79bbf6fe2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=640&q=80"
       
      />
    </Box>
    <Button fullWidth  mx="auto" mt="xl"  className={classes.goldButton}  component="a"
        rel="noopener noreferrer"
        href="#/perawatan/injection/threadlift">
      Threadlift
    </Button>
    </Grid.Col>
    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
      <Box mx="10px">
      <Image
        radius="md"
        src="https://images.unsplash.com/photo-1627552245715-77d79bbf6fe2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=640&q=80"
       
      />
    </Box>
    < Button fullWidth mx="auto" mt="xl" className={classes.goldButton}  component="a"
        rel="noopener noreferrer"
        href="#/perawatan/injection/skinbooster">
      Skinbooster
      </Button>
    </Grid.Col>
    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
      <Box mx="10px">
      <Image
        radius="md"
        src="https://images.unsplash.com/photo-1627552245715-77d79bbf6fe2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=640&q=80"
       
      />
    </Box>
    < Button fullWidth mx="auto" mt="xl" className={classes.goldButton} component="a"
        rel="noopener noreferrer"
        href="#/perawatan/injection/infusion">
    Infusion
      </Button>
    </Grid.Col>
    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
      <Box mx="10px">
      <Image
        radius="md"
        src="https://images.unsplash.com/photo-1627552245715-77d79bbf6fe2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=640&q=80"
       
      />
    </Box>
    < Button fullWidth mx="auto" mt="xl" className={classes.goldButton}  component="a"
        rel="noopener noreferrer"
        href="#/perawatan/injection/filler">
    Filler
      </Button>
    </Grid.Col>
    <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
      <Box mx="10px">
      <Image
        radius="md"
        src="https://images.unsplash.com/photo-1627552245715-77d79bbf6fe2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=640&q=80"
       
      />
    </Box>
    < Button fullWidth mx="auto" mt="xl" className={classes.goldButton}  component="a"
        rel="noopener noreferrer"
        href="#/perawatan/injection/botox">
    Botox
      </Button>
    </Grid.Col>
      </Grid>


        </Box>
        <CustomFonts />
    </>
);
};
  
export default Injection;

