import { Carousel } from "@mantine/carousel";
import { Image, Card, rem, Container, Title, createStyles, Center, Box, Grid, Blockquote, BackgroundImage, Text, Anchor, Space, Divider, Group, Badge, Button, SimpleGrid, List, Table, Flex } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';



import { useRef } from "react";
import ReactGA from "react-ga4";
import { CustomFonts } from "../../../components/CustomFonts";
import logoSozoWhite from '../../../sozo-white.png';
import { Icon360, IconAdjustments, IconStar } from "@tabler/icons-react";


const Filler = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "Filler" });

  const useStyles = createStyles((theme) => ({
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    section3: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section5: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section6: {
      // backgroundColor: '#0033b5',
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      minHeight: '350px',
      [theme.fn.smallerThan('lg')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        minHeight: '180px',
      },
    },

    titleResponsive: {
      fontFamily: 'Gilroy', fontWeight: 700,
      fontSize: '3.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
      },
    },

    titleContainer: {
      position: 'absolute',
      top: '10rem',
      [theme.fn.smallerThan('lg')]: {
        marginBottom: '0rem',
      },
      [theme.fn.smallerThan('md')]: {
        top: '3rem',
      },
      [theme.fn.smallerThan('xs')]: {
        top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
      },
    },

    root: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
      },
    },

    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },

    content: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },

    title: {
      color: '#0035b8',
      lineHeight: 1.05,
      fontSize: '2.7rem',
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },

    iconLocation: {
      color: '#FCC419',
    },

    blueBox: {
      textAlign: 'center',
      padding: theme.spacing.xl,
      borderRadius: theme.radius.xl,
      border: '2px solid #0035b7',
      cursor: 'pointer',
    },

    goldButton: {
      backgroundColor: '#0034b0',
      color: 'white'
    }

  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

  return (

    <>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/filler-banner.png"
        radius="sm"
      >
        <Container mx="auto" mt="3rem" mb="3rem" className={classes.section6} >
          <Flex
            gap="xl"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/filler-icon1.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/filler-icon2.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/filler-icon3.png" alt="Random image" />
          </Flex>
          <Image
            maw={'8rem'}
            src={logoSozoWhite}
            pt="20px"
          />
          <Title className={classes.title} c="white" pt="20px">Filler Treatment</Title>
          <Divider size="md" mt="1rem" maw={100} />
          <Space h="md" />
          <Text c="gray.0" fw={700}>Injection Filler Treatment</Text>
        </Container>
      </BackgroundImage>
      <Box mx="auto" mt="3rem" mb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Text c="#0035b7" ta="left" fz="md" style={{ fontSize: '20px' }}>Filler adalah prosedur penyuntikkan cairan hyaluronic acid ke dalam lapisan kulit untuk memberikan efek plump dan mengisi bagian kulit wajah yang kendur untuk menjadikannya lebih kencang. Treatment ini cocok untuk kamu yang menginginkan wajah terlihat lebih segar dan awet muda secara instan tanpa downtime.</Text></div>
          </SimpleGrid>
        </Container>
      </Box>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-blueleft.png"
      >
        <Box mx="auto" pt="3rem" pb="3rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid align="center">
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <Title className={classes.title} c="white" style={{ fontSize: '4rem;' }}>Manfaat <br></br>Filler <br></br>Treatment </Title>
                      </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <List ta="left" c="#0035b7" style={{ fontSize: '20px' }} type="ordered">
                          <List.Item>Memberikan volume pada kulit yang kendur dan menjadikannya lebih kencang</List.Item>
                          <List.Item>Membuat wajah menjadi lebih simetris</List.Item>
                          <List.Item>Menyamarkan hingga menghilangkan lingkaran hitam di bawah mata</List.Item>
                          <List.Item>Menghilangkan kerutan dan garis halus di wajah</List.Item>
                          <List.Item>Menghilangkan bekas luka</List.Item>
                        </List>
                      </Box>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>
      <Box mx="auto" mt="3rem" mb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Title className={classes.title} ta="center" c="#0035b7">Pilih Jenis Fillermu!</Title>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left" c="#0035b7" >Korean Filler</Title>
                    <Text c="#0035b7" ta="left">Seperti namanya, Korean Filler menggunakan bahan standar yang berasal dari Korea. Prosedur filler ini dilakukan dengan memasukkan bahan filler yang berupa hyaluronic acid ke dalam kulit untuk mengisi volume yang hilang.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='1rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi50.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/filler-asset2.png" alt="Random image" />
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/filler-asset3.png" alt="Random image" />
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left" c="#0035b7" >Premium Filler</Title>
                    <Text c="#0035b7" ta="left">Premium Filler adalah jenis perawatan yang dilakukan dengan memasukkan cairan hyaluronic acid yang berasalh dari Amerika atau Eropa ke dalam kulit wajah. Manfaat filler ini adalah untuk mengisi volume yang hilang, sehingga kulit menjadi lebih kencang.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='1rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi50.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>
      </Box>

      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-blueright.png"
      >
        <Box mx="auto" pt="3rem" pb="3rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid align="center">
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <List ta="left" c="#0035b7" style={{ fontSize: '20px' }} type="ordered">
                          <List.Item>Bersihkan kulit wajah seperti biasa dan gunakan sunblock atau sunscreen setiap harinya</List.Item>
                          <List.Item>Jangan menyentuh atau memencet area<br></br> wajah yang baru saja dilakukan perawatan</List.Item>
                          <List.Item>Hindari penggunaan produk eksfoliasi dan tindakan laser selama dua minggu sebelum dan sesudah treatment</List.Item>
                          <List.Item>Jangan gunakan make-up selama 2-3 hari setelah treatment</List.Item>
                          <List.Item>Jangan melakukan olahraga berat selama 2-3 hari setelah treatment</List.Item>
                        </List>
                      </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <Title className={classes.title} c="white" style={{ fontSize: '4rem;' }} ta="left">Hal-Hal <br></br>yang <br></br>Harus Diperhatikan </Title>
                      </Box>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>
      <Container pt="3rem" pb="3rem">
        <SimpleGrid cols={1}>
          <div><Title className={classes.title} ta="center" c="#0035b7"> Before and After<br></br> Filler Treatment </Title></div>
          <div>
            <Box mx="auto" ta="center" >
              <Grid grow gutter="xs">
                <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                  <Box mx="10px">
                    <Image
                      radius="md"
                      src="https://sozoskinclinic.com/assets/img/filler-asset7.png"
                      style={{ maxWidth: '350px', margin: 'auto' }}
                    />
                  </Box>
                </Grid.Col>
                <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                  <Box mx="10px">
                    <Image
                      radius="md"
                      src="https://sozoskinclinic.com/assets/img/filler-asset8.png"
                      style={{ maxWidth: '350px', margin: 'auto' }}
                    />
                  </Box>
                </Grid.Col>

              </Grid>
            </Box>
          </div>
        </SimpleGrid>
      </Container>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/footer.png"
      >
        <Box mx="auto" pt="2rem" c="#d4f4f4" className={classes.section6} >
          <Container>
            <Grid grow gutter="xs">
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">
                  <Text ta="left" c="gray.0" style={{ fontSize: '25px' }}>  Konsultasikan permasalahan <br></br>wajah dan kulitmu secara  <br></br>gratis bersama beauty  <br></br>consultant kami untuk  <br></br>mendapatkan rekomendasi  <br></br>treatment terbaik untukmu. </Text>
                </Box>
              </Grid.Col>
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">
                </Box>
              </Grid.Col>
            </Grid>
          </Container>
        </Box>
      </BackgroundImage>

      <Container mt="4rem" mb="4rem" ta="center">
        <SimpleGrid cols={1}>
          <div><Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank"><Button mx="auto" mt="xl" radius="xl" size="lg" className={classes.goldButton}>
            <Title order={2}>Konsultasi sekarang</Title>
          </Button></Anchor></div>
        </SimpleGrid>
      </Container>
      <CustomFonts />
    </>
  );
};

export default Filler;

