import { Carousel } from "@mantine/carousel";
import { Image, Card, rem, Container, Title, createStyles, Center, Box, Grid, Blockquote, BackgroundImage, Text, Anchor, Space, Divider, Group, Badge, Button, SimpleGrid, List, Table, Flex } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';



import { useRef } from "react";
import ReactGA from "react-ga4";
import { CustomFonts } from "../../../components/CustomFonts";
import logoSozoWhite from '../../../sozo-white.png';
import { Icon360, IconAdjustments, IconStar } from "@tabler/icons-react";


const DermaPeel = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "DermaPeel" });

  const useStyles = createStyles((theme) => ({
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    section3: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section5: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section6: {
      // backgroundColor: '#0033b5',
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      minHeight: '350px',
      [theme.fn.smallerThan('lg')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        minHeight: '180px',
      },
    },

    titleResponsive: {
      fontFamily: 'Gilroy', fontWeight: 700,
      fontSize: '3.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
      },
    },

    titleContainer: {
      position: 'absolute',
      top: '10rem',
      [theme.fn.smallerThan('lg')]: {
        marginBottom: '0rem',
      },
      [theme.fn.smallerThan('md')]: {
        top: '3rem',
      },
      [theme.fn.smallerThan('xs')]: {
        top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
      },
    },

    root: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
      },
    },

    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },

    content: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },

    title: {
      color: '#0035b8',
      lineHeight: 1.05,
      fontSize: '2.7rem',
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },

    iconLocation: {
      color: '#FCC419',
    },

    blueBox: {
      textAlign: 'center',
      padding: theme.spacing.xl,
      borderRadius: theme.radius.xl,
      border: '2px solid #0035b7',
      cursor: 'pointer',
    },

    goldButton: {
      backgroundColor: '#0034b0',
      color: 'white'
    }

  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

  return (

    <>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/dermapeel-banner.png"
        radius="sm"
      >
        <Container mx="auto" className={classes.section6} >
          <Flex
            gap="xl"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/dermapeel-icon1.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/dermapeel-icon2.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/dermapeel-icon3.png" alt="Random image" />
          </Flex>
          <Image
            maw={'8rem'}
            src={logoSozoWhite}
            pt="20px"
          />
          <Title className={classes.title} c="white" pt="20px"> Derma Peel Treatment  </Title>
          <Divider size="md" mt="1rem" maw={100} />
          <Space h="md" />
        </Container>

      </BackgroundImage>

      <Box mx="auto" mt="3rem" mb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Text c="#0035b7" ta="left" fz="md" style={{ fontSize: '20px' }}>Derma Peel adalah treatment yang dilakukan dengan menggunakan chemical peeling untuk menghilangkan sel kulit mati pada lapisan kulit dan meregenerasinya dengan kulit baru yang lebih lembut dan cerah. Treatment ini sangat direkomendasikan untuk kamu yang menginginkan kulit cerah, lembut, dan halus. Sesuaikan jenis treatment chemical peeling dari SOZO Clinic untuk jenis kulitmu. Mulai dari Glow Peel, Acne Peel, Dazzling Glow Peel, dan Eternal Bloom Peel</Text></div>
          </SimpleGrid>
        </Container>
      </Box>
      <Box mx="auto" pt="3rem" pb="3rem">
        <div>
          <Title className={classes.title} ta="center"> Pilih Jenis Chemical Peeling Treatment<br></br> Untuk Kulitmu </Title>
        </div>
        <Container mt="1rem">
          <div>
            <Box mx="auto" className={classes.blueBox}>
              <Grid>
                <Grid.Col lg={6}>
                  <Title className={classes.title} ta="left">Glow Peel</Title>
                  <Text c="#0035b7" ta="left">Glow Peel adalah prosedur treatment estetik medis yang dilakukan dengan mengoleskan cairan kimia medis untuk memeberikan efek lifting secara instan, glow, mengurangi kerutan, meningkatkan sintesis kolagen, dan memperbaiki kulit secara umum. Perawatan ini sangat aman, bahkan untuk jenis kulit sensitif, kering, dan dapat dilakukan 3 hari sebelum acara besar.</Text>
                  <Flex
                    gap="xl"
                    justify="flex-start"
                    align="flex-start"
                    direction="row"
                    wrap="wrap"
                    mt='0.5rem'
                  >
                    <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                  </Flex>
                </Grid.Col>
                <Grid.Col lg={6}>
                  <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/dermapeel-asset11.png" alt="Random image" />
                </Grid.Col>
              </Grid>
            </Box>
          </div>
        </Container>
      </Box>
      <Box mx="auto" mt="3rem" mb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Box mx="auto" className={classes.blueBox}>
                <Grid>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/dermapeel-asset13.png" alt="Random image" />
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left">Acne Peel</Title>
                    <Text c="#0035b7" ta="left">Raih kulit bebas jerawat dengan Acne Peel SOZO Clinic. Acne Peel treatment merupakan prosedur perawatan dengan memanfaatkan cairan kimia medis yang bermanfaat untuk menyingkirkan sumbatan pori-pori dan komedo, serta mengeringkan jerawat aktif sekaligus membuat kulit tampak lebih bercahaya dan terbebas dari noda hitam bekas jerawat. <br></br><br></br>Treatment ini cocok untuk kamu yang memiliki kulit berminyak dan berjerawat serta mengalami hiperpigmentasi.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='0.5rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
            <div>
              <Box mx="auto" className={classes.blueBox} mt="2rem" mb="2rem">
                <Grid>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left">Dazzling <br></br>Glow Peel</Title>
                    <Text c="#0035b7" ta="left">Dazzling Glow Peel merupakan treatment dengan chemical peeling yang berfungsi untuk meregenerasi kulit wajah, mengurangi kerutan, meningkatkan sintesis kolagen, dan memperbaiki penampilan kulit secara keseluruhan. Dapatkan kulit tampak lebih muda dengan Dazzling Glow Peel SOZO Clinic.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='1rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/dermapeel-asset15.png" alt="Random image" />
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
            <div>
              <Box mx="auto" className={classes.blueBox} mt="2rem" mb="2rem">
                <Grid>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/dermapeel-asset17.png" alt="Random image" />
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left">Eternal Bloom Peel</Title>
                    <Text c="#0035b7" ta="left">Lawan tanda-tanda penuaan dengan Eternal Bloom Peel Treatment SOZO Clinic. Eternal Bloom Peel adalah treatment yang inovatif dan intensif untuk mempercepat regenerasi sel-sel kulit, membuat kulit terlihat lebih muda, serta melawan tanda-tanda penuaan. Treatment ini sangat cocok bagi kamu yang memiliki masalah bekas jerawat, kerutan halus, tanda-tanda penuaan, dan hiperpigmentasi.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='0.5rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi20.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>
      </Box>
      <Box mx="auto" mt="3rem" mb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>

            </div>
          </SimpleGrid>
        </Container>
      </Box>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-blueright.png"
      >
        <Box mx="auto" pt="6rem" pb="6rem" mt="3rem" mb="3rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid align="center">
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <List ta="left" c="#0035b7" style={{ fontSize: '20px' }}>
                          <List.Item>Sebelum dilakukan tindakan, kulit akan dibersihkan terlebih dahulu.</List.Item>
                          <List.Item>Dokter akan mengaplikasikan larutan chemical peeling sesuai dengan kebutuhan kulit. <br></br>Perawatan ini merangsang terbentuknya lapisan <br></br>kulit baru yang lebih halus dan lembut.</List.Item>
                          <List.Item>Dokter akan melakukan treatment selama 20-30 menit. Kamu akan merasakan sensasi hangat pada kulit selama treatment berlangsung.</List.Item>
                          <List.Item>Setelah treatment selesai, kulitmu akan dibersihkan dan kemudian terapis akan mengaplikasikan krim wajah atau sunblock.</List.Item>
                        </List>
                      </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <Title className={classes.title} c="white" style={{ fontSize: '4rem;' }} ta="left">Tahap Perawatan Chemical Peeling<br></br> SOZO Clinic</Title>
                      </Box>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-blueleft.png"
      >
        <Box mx="auto" pt="6rem" pb="6rem" mt="3rem" mb="3rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid align="center">
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <Title className={classes.title} c="white" style={{ fontSize: '4rem;' }} ta="left">Manfaat Chemical Peeling SOZO Clinic</Title>
                      </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <List ta="left" c="#0035b7" style={{ fontSize: '20px' }}>
                          <List.Item>Memperbaiki tekstur kulit</List.Item>
                          <List.Item>Mengurangi bekas jerawat atau hiperpigmentasi akibat paparan sinar matahari</List.Item>
                          <List.Item>Membuat kulit menjadi lebih halus, cerah, dan sehat dari dalam</List.Item>
                          <List.Item>Mempercepat proses penyembuhan jerawat</List.Item>
                          <List.Item>Mengurangi garis-garis halus dan menyamarkan kerutan serta tanda-tanda penuaan</List.Item>
                        </List>
                      </Box>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/footer.png"
      >
        <Box mx="auto" pt="2rem" c="#d4f4f4" className={classes.section6} >
          <Container>
            <Grid grow gutter="xs">
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">
                  <Text ta="left" c="gray.0" style={{ fontSize: '25px' }}>  Konsultasikan permasalahan <br></br>wajah dan kulitmu secara  <br></br>gratis bersama beauty  <br></br>consultant kami untuk  <br></br>mendapatkan rekomendasi  <br></br>treatment terbaik untukmu. </Text>
                </Box>
              </Grid.Col>
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">

                </Box>
              </Grid.Col>
            </Grid>
          </Container>
        </Box>
      </BackgroundImage>
      <Container mt="4rem" mb="4rem" ta="center">
        <SimpleGrid cols={1}>
          <div><Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank"><Button mx="auto" mt="xl" radius="xl" size="lg" className={classes.goldButton}>
            <Title order={2}>Konsultasi sekarang</Title>
          </Button></Anchor></div>
        </SimpleGrid>
      </Container>
      <CustomFonts />
    </>
  );
};

export default DermaPeel;

