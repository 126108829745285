import { Carousel } from "@mantine/carousel";
import { Image, Card, rem, Container, Title, createStyles, Center, Box, Grid, Blockquote, BackgroundImage, Text, Anchor, Space, Divider, Group, Badge, Button, SimpleGrid, List, Table, Flex } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';



import { useRef } from "react";
import ReactGA from "react-ga4";
import { CustomFonts } from "../../../components/CustomFonts";
import logoSozoWhite from '../../../sozo-white.png';
import { Icon360, IconAdjustments, IconStar } from "@tabler/icons-react";


const IplFace = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "IplFace" });

  const useStyles = createStyles((theme) => ({
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    section3: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section5: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section6: {
      // backgroundColor: '#0033b5',
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      minHeight: '350px',
      [theme.fn.smallerThan('lg')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        minHeight: '180px',
      },
    },

    titleResponsive: {
      fontFamily: 'Gilroy', fontWeight: 700,
      fontSize: '3.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
      },
    },

    titleContainer: {
      position: 'absolute',
      top: '10rem',
      [theme.fn.smallerThan('lg')]: {
        marginBottom: '0rem',
      },
      [theme.fn.smallerThan('md')]: {
        top: '3rem',
      },
      [theme.fn.smallerThan('xs')]: {
        top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
      },
    },

    root: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
      },
    },

    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },

    content: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },

    title: {
      color: '#0035b8',
      lineHeight: 1.05,
      fontSize: '2.7rem',
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },

    iconLocation: {
      color: '#FCC419',
    },

    blueBox: {
      textAlign: 'center',
      padding: theme.spacing.xl,
      borderRadius: theme.radius.xl,
      border: '2px solid #0035b7',
      cursor: 'pointer',
    },

    goldButton: {
      backgroundColor: '#0034b0',
      color: 'white'
    }

  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

  return (

    <>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/iplface-banner.png"
        radius="sm"
      >
        <Container mx="auto" className={classes.section6} >
          <Flex
            gap="xl"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/iplface-asset1.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/iplface-asset2.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/iplface-asset3.png" alt="Random image" />
          </Flex>

          <Image
            maw={'8rem'}
            src={logoSozoWhite}
            pt="20px"
          />
          <Title className={classes.title} c="white" pt="20px">
            IPL Face Treatment
          </Title>

          <Divider size="md" mt="1rem" maw={100} />
          <Space h="md" />
          <Text c="gray.0" fw={700}>Upgrade Your Skin with <br></br>IPL Face Treatment SOZO Clinic</Text>
        </Container>
      </BackgroundImage>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-grey.png"
      >
        <Box mx="auto" pt="2rem" pb="2rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                {/* <Title order={2} ta="center" c="#0035b7"> Upgrade Your Skin with IPL Face Treatment SOZO Clinic </Title> */}
                <Text c="#0035b7" ta="left" fz="md" style={{ fontSize: '20px' }}>IPL atau Intense Pulse Light adalah perawatan wajah yang memanfaatkan energi cahaya untuk mengatasi berbagai masalah seperti mencegah penuaan dini, menyamarkan kerutan halus di wajah, mencerahkan kulit, hingga menghilangkan jerawat. IPL bekerja dengan menstimulasi produksi kolagen serta elastin yang bermanfaat untuk peremajaan kulit. Treatment ini cocok untuk kamu yang memiliki masalah jerawat, pori-pori besar, kerutan halus, dan menginginkan kulit cerah bercahaya.</Text></div>
            </SimpleGrid>
          </Container>
        </Box>
        <Box mx="auto" pt="2rem" pb="2rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto" ta="center" className={classes.blueBox}>
                  <Grid>
                    <Grid.Col lg={6}>
                      <Title className={classes.title} ta="left" c="#0035b7" pt="10px">IPL Glow</Title>
                      <Text c="#0035b7" ta="left">IPL Glow merupakan treatment yang menggunakan spektrum gelombang cahaya untuk membuat kulitmu menjadi lebih sehat, kencang, dan glowing dari dalam. Teknologi Intense Pulse Light di SOZO Clinic mampu meregenerasi kolagen lebih cepat untuk meremajakan kulit dan membuatnya lebih cerah dari dalam.</Text>
                      <Flex
                        gap="xl"
                        justify="flex-start"
                        align="flex-start"
                        direction="row"
                        wrap="wrap"
                        mt='0.5rem'
                      >
                        <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                      </Flex>
                    </Grid.Col>
                    <Grid.Col lg={6}>
                      <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/iplface-asset9.png" alt="Random image" />
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>
      <Box mx="auto" mt="3rem" mb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Box mx="auto" ta="center" className={classes.blueBox}>
                <Grid>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/iplface-asset13.png" alt="Random image" pt="1rem" />
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} c="#0035b7" ta="left">IPL Acne</Title>
                    <Text c="#0035b7" ta="left" pt="1rem">IPL Acne adalah perawatan jerawat yang menggunakan teknologi Intense Pulsed Light (IPL) yang efektid mengeringkan jerawat dengan membunuh bakteri penyebab jerawat dan mengobati peradangan secara aman.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='0.5rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>
      </Box>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-blueleft.png"
      >
        <Box mx="auto" pt="3rem" pb="3rem" mt="3rem" mb="3rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid align="center">
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <Title className={classes.title} c="white" style={{ fontSize: '4rem;' }} ta="left"> Manfaat <br></br>IPL Face Treatment<br></br> SOZO Clinic</Title>
                      </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <List ta="left" c="#0035b7" style={{ fontSize: '20px' }}>
                          <List.Item>Membunuh bakteri penyebab<br></br> jerawat</List.Item>
                          <List.Item>Mengurangi peradangan akibat <br></br>jerawat</List.Item>
                          <List.Item>Menyamarkan bekas jerawat PIH <br></br>pada kulit</List.Item>
                          <List.Item>Mengurangi hiperpigmentasi pada <br></br>kulit</List.Item>
                          <List.Item>Menghilangkan kerutan halus dan <br></br>tanda-tanda penuaan dini</List.Item>
                        </List>
                      </Box>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-blueright.png"
      >
        <Box mx="auto" pt="3rem" pb="3rem" mt="3rem" mb="3rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid align="center">
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <List ta="left" c="#0035b7" type="ordered" style={{ fontSize: '20px' }}>
                          <List.Item>Menggunakan teknologi dan peralatan <br></br>terbaik di kelasnya.</List.Item>
                          <List.Item>Dilakukan oleh dokter dan terapis profesional.</List.Item>
                          <List.Item>Efektif mengurangi hiperpigmentasi, jerawat, <br></br> dan warna kulit tidak merata.</List.Item>
                          <List.Item>Tidak menimbulkan rasa sakit yang berlebih, <br></br> kamu dapat beraktivitas seperti biasa  <br></br>setelah perawatan.</List.Item>
                          <List.Item>Hasil langsung terlihat sejak pertama kali  <br></br>perawatan.</List.Item>
                        </List>
                      </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <Title className={classes.title} c="white" style={{ fontSize: '4rem;' }} ta="left">Keunggulan <br></br>IPL Face <br></br>Treatment di <br></br>SOZO Clinic</Title>
                      </Box>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>
      <Box mx="auto" mt="3rem" mb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div><Title className={classes.title} ta="center" c="#0035b7">Apa yang Harus Dilakukan  <br></br>Sebelum dan Sesudah Perawatan IPL?</Title></div>
            <div>
              <Box mx="auto" ta="center" mt="1rem" mb="1rem">
                <Grid justify="center" >
                  <Grid.Col span={6} style={{ maxWidth: '400px' }}>
                    <Box mx="10px">
                      <Table withBorder withColumnBorders className={classes.blueBox}>
                        <thead>
                          <tr>
                            <th style={{ backgroundColor: '#3483df' }}><Title order={3} ta="center" c="gray.0">Sebelum Treatment</Title></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Sampaikan kepada dokter mengenai kondisi kulitmu</Text></td>
                          </tr>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Hindari paparan sinar matahari langsung 2 minggu sebelum perawatan</Text></td>
                          </tr>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Jangan gunakan produk eksfoliasi 2 minggu sebelum perawatan</Text></td>
                          </tr>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Jangan melakukan waxing, pencabutan rambut secara manual atau dengan krim perontok 2 minggu sebelum perawatan</Text></td>
                          </tr>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Pastikan area wajah tidak kering dan iritasi.</Text></td>
                          </tr>
                        </tbody>
                      </Table>
                    </Box>
                  </Grid.Col>
                  <Grid.Col span={6} style={{ maxWidth: '400px' }}>
                    <Box mx="10px">
                      <Table withBorder withColumnBorders className={classes.blueBox}>
                        <thead>
                          <tr>
                            <th style={{ backgroundColor: '#0035b7' }}><Title order={3} ta="center" c="gray.0">Sesudah Treatment</Title></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Selalu gunakan sunscreen untuk melindungi kulit dari paparan sinar matahari</Text></td>
                          </tr>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Jangan lakukan waxing atau peeling pada area perawatan</Text></td>
                          </tr>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Hindari penggunaana produk eksfoliasi seperti retinol, AHA, BHA, dan PHA</Text></td>
                          </tr>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Jangan melakukan tanning atau berjemur di bawah sinar matahari langsung</Text></td>
                          </tr>
                          <tr>
                            <td><Text c="#0035b7" ta="left">Hindari waxing, pilih cukur sebagai gantinya</Text></td>
                          </tr>
                        </tbody>
                      </Table>
                    </Box>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>
      </Box>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/footer.png"
      >
        <Box mx="auto" mt="2rem" c="#d4f4f4" className={classes.section6} >
          <Container>
            <Grid grow gutter="xs">
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">
                  <Text ta="left" c="gray.0" style={{ fontSize: '25px' }}>  Konsultasikan permasalahan <br></br>wajah dan kulitmu secara  <br></br>gratis bersama beauty  <br></br>consultant kami untuk  <br></br>mendapatkan rekomendasi  <br></br>treatment terbaik untukmu. </Text>
                </Box>
              </Grid.Col>
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">

                </Box>
              </Grid.Col>
            </Grid>
          </Container>
        </Box>

      </BackgroundImage>

      <Container mt="4rem" mb="4rem" ta="center">
        <SimpleGrid cols={1}>
          <div><Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank"><Button mx="auto" mt="xl" radius="xl" size="lg" className={classes.goldButton}>
            <Title order={2}>Konsultasi sekarang</Title>
          </Button></Anchor></div>
        </SimpleGrid>
      </Container>
      <CustomFonts />
    </>
  );
};

export default IplFace;

