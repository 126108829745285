import { Carousel } from "@mantine/carousel";
import { Image, Card, rem, Container, Title, createStyles, Center, Box, Grid, Blockquote, BackgroundImage, Text, Anchor, Space, Divider, Group, Badge, Button, SimpleGrid, List, Table, Flex } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';



import { useRef } from "react";
import ReactGA from "react-ga4";
import { CustomFonts } from "../../../components/CustomFonts";
import logoSozoWhite from '../../../sozo-white.png';
import { IconAdjustments, IconStar } from "@tabler/icons-react";


const Laser = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "Laser" });

  const useStyles = createStyles((theme) => ({
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    section3: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section5: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section6: {
      // backgroundColor: '#0033b5',
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      minHeight: '350px',
      [theme.fn.smallerThan('lg')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        minHeight: '180px',
      },
    },

    titleResponsive: {
      fontFamily: 'Gilroy', fontWeight: 700,
      fontSize: '3.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
      },
    },

    titleContainer: {
      position: 'absolute',
      top: '10rem',
      [theme.fn.smallerThan('lg')]: {
        marginBottom: '0rem',
      },
      [theme.fn.smallerThan('md')]: {
        top: '3rem',
      },
      [theme.fn.smallerThan('xs')]: {
        top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
      },
    },

    root: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
      },
    },

    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },

    content: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },

    title: {
      color: '#0035b8',
      lineHeight: 1.05,
      fontSize: '2.7rem',
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },

    iconLocation: {
      color: '#FCC419',
    },

    blueBox: {
      textAlign: 'center',
      padding: theme.spacing.xl,
      borderRadius: theme.radius.xl,
      border: '2px solid #0035b7',
      cursor: 'pointer',
    },

    goldButton: {
      backgroundColor: '#0034b0',
      color: 'white'
    }

  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

  return (

    <>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/laser-banner.png"
        radius="sm"
      >
        <Container mx="auto" className={classes.section6} >
          <Flex
            gap="xl"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/laser-icon1.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/laser-icon2.png" alt="Random image" />
          </Flex>

          <Image
            maw={'8rem'}
            src={logoSozoWhite}
            pt="20px"
          />

          <Title className={classes.title} c="white" pt="20px">
            Laser Treatment
          </Title>

          <Divider size="md" mt="1rem" maw={100} />
          <Space h="md" />
          <Text c="gray.0" fw={700}>Best Improvement For Your Skin</Text>
        </Container>
      </BackgroundImage>
      <Box mx="auto" pt="3rem" pb="3rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Title className={classes.title}>
                Improve Your Skin with the Best Laser Treatment
              </Title>
              <Text c="#0035b7" ta="center" style={{ fontSize: '20px' }} pt="1rem">Dapatkan kulit wajah yang halus dan kencang dengan Laser Treatment terbaik dari SOZO Clinic! Perawatan ini menjadi solusis inovatif untuk menyamarkan noda, dan meremajakan kulit wajahmu tanpa rasa sakit. Dengan teknologi Nd Yag, laser ini bekerja efektif untuk mengatasi masalah seperti melasma, pigmentasi kulit, flek hitam akibat jerawat, dan bibir menghitam. Laser treatment ini cocok untuk semua jenis kulit dan usia. Jadi, segera raih kulit cerah impian dan tunjukkan kecantikan alami kulitmu dengan Laser Treatment dari SOZO Clinic!</Text></div>
          </SimpleGrid>
        </Container>
      </Box>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-grey.png"
      >

        <Box mx="auto" pt="1rem" pb="1rem" mt="2rem" mb="2rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Title className={classes.title} ta="center">
                  Pilih Solusi Perawatan <br></br>yang Pas Untukmu!
                </Title>
              </div>
              <div>
                <Box mx="auto" ta="center" className={classes.blueBox} mt="1rem">
                  <Grid>
                    <Grid.Col lg={6}>
                      <Title className={classes.title} ta="left">  Nanolux Laser</Title>
                      <Text c="#0035b7" ta="left" pt="1rem">Menggunakan laser Q-switched NdYag dengan panjang gelombang 1064 nm, Nanolux Laser merupakan perawatan yang dapat membantumu mengatasi masalah pigmentasi seperti melasma dan juga dapat meremajakan kulit dengan treatment berkala.</Text>
                      <Flex
                        gap="xl"
                        justify="flex-start"
                        align="flex-start"
                        direction="row"
                        wrap="wrap"
                        mt='0.5rem'
                      >
                        <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                      </Flex>
                    </Grid.Col>
                    <Grid.Col lg={6}>
                      <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/laser-asset9.png" alt="Random image" />
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>
      <Box mx="auto" pt="1rem" pb="1rem" mt="2rem" mb="1rem">
        <Container mt="1rem">
          <div>
            <Box mx="auto" ta="center" className={classes.blueBox}>
              <Grid>
                <Grid.Col lg={6}>
                  <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/laser-assetperawatan2.png" alt="Random image" pt="1rem" />
                </Grid.Col>
                <Grid.Col lg={6}>
                  <Title className={classes.title} ta="left" >Picolux Laser</Title>
                  <Text c="#0035b7" ta="left">Picolux laser merupakan perawatan menggunakan teknologi picosecond yang efektif mengurangi masalah pigmentasi kulit seperti flek hitam akibat jerawat, meratakan warna kulit, melasma,, tanda lahir, serta menghapus tato (tattoo removal).<br></br><br></br> Selain itu, pico laser dapat meningkatkan produksi elastin dan kolagen kulit sehingga menghasilkan kulit yang lebih lembut, sehat, dan halus. <br></br><br></br>Dengan teknologi laser berkecepatan 1/1000 detik ini, Picolux laser cocok untuk kamu yang ingin kulit bersih, sehat, dan glowing dengan hasil yang cepat dan maksimal.</Text>
                  <Flex
                    gap="xl"
                    justify="flex-start"
                    align="flex-start"
                    direction="row"
                    wrap="wrap"
                    mt='0.5rem'
                  >
                    <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                  </Flex>
                </Grid.Col>
              </Grid>
            </Box>
          </div>
          <div>
            <Box mx="auto" ta="center" pt="1rem" pb="1rem" mt="3rem" mb="3rem" className={classes.blueBox}>
              <Grid>
                <Grid.Col lg={6}>
                  <Title className={classes.title} ta="left"> Pink Lips</Title>
                  <Text c="#0035b7" ta="left" pt="1rem">Dapatkan bibir pink alami dan atasi masalah bibir yang menghitam dengan Pink Lips Treatment. Dengan teknologi laser terkini, treatment ini mampu meregenerasi sel kulit, menstimulasi kolagen, dan mencegah bibir kering untuk mengembalikan warna alami bibir.</Text>
                  <Flex
                    gap="xl"
                    justify="flex-start"
                    align="flex-start"
                    direction="row"
                    wrap="wrap"
                    mt='0.5rem'
                  >
                    <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                  </Flex>
                </Grid.Col>
                <Grid.Col lg={6}>
                  <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/laser-asset14.png" alt="Random image" />
                </Grid.Col>
              </Grid>
            </Box>
          </div>
          <div>
            <Box mx="auto" ta="center" pt="1rem" pb="1rem" mt="3rem" mb="1rem" className={classes.blueBox}>
              <Grid align="center">
                <Grid.Col lg={6}>
                  <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/laser-asset17.png" alt="Random image" />
                </Grid.Col>
                <Grid.Col lg={6}>
                  <Title className={classes.title} ta="left">HIFU</Title>
                  <Text c="#0035b7" ta="left" pt="1rem">HIFU treatment atau High Intensity Focused Ultrasound merupakan perawatan wajah yang memanfaatkan teknologi ultrasound yang berfungsi untuk meningkatkan elastisitas, kekenyalan kulit, dan mengencangkan kulit yang mulai mengendur, terutama di area mata, dagu, dan leher.</Text>
                  <Flex
                    gap="xl"
                    justify="flex-start"
                    align="flex-start"
                    direction="row"
                    wrap="wrap"
                    mt='0.5rem'
                  >
                    <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                  </Flex>
                </Grid.Col>
              </Grid>
            </Box>
          </div>

        </Container>
      </Box>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-blueleft.png"
      >
        <Box mx="auto" pt="6rem" pb="6rem" mt="3rem" mb="3rem">
          <Container pt="1rem" pb="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid align="center">
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <Title className={classes.title} c="white" style={{ fontSize: '4rem;' }} ta="left"> Keunggulan <br></br>Laser <br></br>Treatment<br></br> SOZO Clinic </Title>
                      </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <List ta="left" c="#0035b7" style={{ fontSize: '20px' }}>
                          <List.Item>Laser treatment premium dengan harga terjangkau.</List.Item>
                          <List.Item>Menggunakan teknologi Ny Dag sebagai salah satu teknologi laser terbaik.</List.Item>
                          <List.Item>Dilakukan langsung oleh dokter profesional.</List.Item>
                          <List.Item>Perawatan nyaman dan tanpa rasa sakit. Kamu bisa langsung beraktivitas lagi setelah perawatan.</List.Item>
                          <List.Item>Improvement nyata akan langsung terlihat dalam 1x treatment.</List.Item>
                        </List>
                      </Box>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-blueright.png"
      >
        <Box mx="auto" pt="6rem" pb="6rem" mt="3rem" mb="3rem">
          <Container mt="1rem">
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto" ta="center" >
                  <Grid align="center">
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <List ta="left" c="#0035b7" style={{ fontSize: '20px' }}>
                          <List.Item>Sebelum treatment, hentikan penggunakan krim dokter atau exfoliating skincare 24 jam sebelumnya.</List.Item>
                          <List.Item>Setelah treatment, jangan gunakan krim dokter, make-up, dan facial scrub 24 jam setelah <br></br> perawatan.</List.Item>
                          <List.Item>Akan terjadi pengelupasan pada kulit setelah treatment, biarkan pengelupasan terjadi secara alami dan jangan dikelupas menggunakan tangan.</List.Item>
                          <List.Item>Selalu gunakan sunscreen sebelum beraktivitas.</List.Item>
                          <List.Item>Lakukan penjadwalan treatment selanjutnya 4 minggu setelah treatment awal.</List.Item>
                          <List.Item>Ulangi treatment 4-5 kali untuk mendapatkan hasil yang lebih maksimal.</List.Item>
                        </List>
                      </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <Title className={classes.title} c="white" style={{ fontSize: '4rem;' }} ta="left">Dos and Don’ts <br></br>Sebelum dan <br></br>Sesudah <br></br>Treatment</Title>
                      </Box>
                    </Grid.Col>

                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-grey.png"
      >
        <Container pt="3rem" pb="2rem">
          <SimpleGrid cols={1}>
            <div><Title className={classes.title} ta="center" c="#0035b7"> Before and After <br></br>Laser Treatment </Title></div>
            <div>
              <Box mx="auto" ta="center" >
                <Grid grow gutter="xs">
                  <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                    <Box mx="10px">
                      <Image
                        radius="md"
                        src="https://sozoskinclinic.com/assets/img/laser-asset25.png"
                        style={{ maxWidth: '350px', margin: 'auto' }}
                      />
                    </Box>
                  </Grid.Col>
                  <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                    <Box mx="10px">
                      <Image
                        radius="md"
                        src="https://sozoskinclinic.com/assets/img/laser-asset26.png"
                        style={{ maxWidth: '350px', margin: 'auto' }}
                      />
                    </Box>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>
        <Container pt="2rem" pb="3rem">
          <SimpleGrid cols={1}>
            <div><Title className={classes.title} ta="center" c="#0035b7"> Before and After <br></br>Laser Treatment - Pink Lips </Title></div>
            <div>
              <Box mx="auto" ta="center" >
                <Grid grow gutter="xs">
                  <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                    <Box mx="10px">
                      <Image
                        radius="md"
                        src="https://sozoskinclinic.com/assets/img/laser-asset29.png"
                        style={{ maxWidth: '350px', margin: 'auto' }}
                      />
                    </Box>
                  </Grid.Col>
                  <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                    <Box mx="10px">
                      <Image
                        radius="md"
                        src="https://sozoskinclinic.com/assets/img/laser-asset31.png"
                        style={{ maxWidth: '350px', margin: 'auto' }}
                      />
                    </Box>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>
      </BackgroundImage>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/footer.png"
      >
        <Box mx="auto" c="#d4f4f4" className={classes.section6} >
          <Container>
            <Grid grow gutter="xs">
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">
                  <Text ta="left" c="gray.0" style={{ fontSize: '25px' }}>  Konsultasikan permasalahan <br></br>wajah dan kulitmu secara  <br></br>gratis bersama beauty  <br></br>consultant kami untuk  <br></br>mendapatkan rekomendasi  <br></br>treatment terbaik untukmu. </Text>
                </Box>
              </Grid.Col>
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">

                </Box>
              </Grid.Col>
            </Grid>
          </Container>
        </Box>
      </BackgroundImage>
      <Container mt="4rem" mb="4rem" ta="center">
        <SimpleGrid cols={1}>
          <div><Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank"><Button mx="auto" mt="xl" radius="xl" size="lg" className={classes.goldButton}>
            <Title order={2}>Konsultasi sekarang</Title>
          </Button></Anchor></div>
        </SimpleGrid>
      </Container>
      <CustomFonts />
    </>
  );
};

export default Laser;

