import { Carousel } from "@mantine/carousel";
import { Image, Card, rem, Container, Title, createStyles, Center, Box, Grid, Blockquote, BackgroundImage, Text, Anchor, Space, Divider, Group, Badge, Button, SimpleGrid, List, Table, Flex } from "@mantine/core";
import Autoplay from 'embla-carousel-autoplay';
import { useRef } from "react";
import ReactGA from "react-ga4";
import { CustomFonts } from "../../components/CustomFonts";
import logoSozoWhite from '../../sozo-white.png';
import React from 'react';
import { ReactSEOMetaTags } from "react-seo-meta-tags";
import { Helmet } from "react-helmet-async";


const HairRemoval = () => {

  ReactGA.send({ hitType: "pageview", page: "/", title: "HairRemoval" });

  const useStyles = createStyles((theme) => ({
    abosolute: {
      position: 'absolute',
      margin: '0 0 0 35%',
      border: '3px solid #FAB005!important',
      fontFamily: 'Poppins',
      fontWeight: 700,
      [theme.fn.smallerThan('md')]: {
        margin: '0 0 0 35%',
      },
    },
    section2: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      // backgroundColor: '#d4f4f4',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },

    section3: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section4: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section5: {
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      [theme.fn.smallerThan('xs')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
    },
    section6: {
      // backgroundColor: '#0033b5',
      paddingTop: rem(50),
      paddingBottom: rem(50),
      paddingLeft: '2rem',
      paddingRight: '2rem',
      minHeight: '350px',
      [theme.fn.smallerThan('lg')]: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        minHeight: '180px',
      },
    },

    titleResponsive: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '3.125rem',
      [theme.fn.smallerThan('md')]: {
        fontSize: '2rem',
      },
      [theme.fn.smallerThan('xs')]: {
        fontSize: '1.2rem',
      },
    },

    titleContainer: {
      position: 'absolute',
      top: '10rem',
      fontFamily: 'Poppins',
      fontWeight: 700,
      [theme.fn.smallerThan('lg')]: {
        marginBottom: '0rem',
      },
      [theme.fn.smallerThan('md')]: {
        top: '3rem',
      },
      [theme.fn.smallerThan('xs')]: {
        top: '3rem',
      },

    },

    marginOnMobile: {
      [theme.fn.smallerThan('xs')]: {
        marginBottom: '0rem',
      },
    },

    root: {
      paddingTop: `calc(${theme.spacing.xl} * 3)`,
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
      fontFamily: 'Poppins',
      fontWeight: 700,
    },

    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
      },
    },

    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },

    content: {
      paddingTop: `calc(${theme.spacing.xl} * 2)`,
      paddingBottom: `calc(${theme.spacing.xl} * 2)`,
      marginRight: `calc(${theme.spacing.xl} * 3)`,

      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },

    title: {
      color: '#0035b8',
      lineHeight: 1.05,
      fontSize: '2.7rem',
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: rem(34),
        lineHeight: 1.15,
      },
    },

    gridMobile: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '24.999%',
        padding: '1px'
      },
    },

    gridMobileServices: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '34.999%',
        // padding: '30px'
      },
    },

    gridMobileLocation: {
      [theme.fn.smallerThan('xs')]: {
        flexBasis: '50%',
        // padding: '30px'
      },
    },

    imgMobile: {
      [theme.fn.smallerThan('xs')]: {
        width: '70px',
        maxWidth: '3.25rem',
        height: '0.125rem'
      },
    },

    cardResponsive: {
      minHeight: '320px',
      [theme.fn.smallerThan('xs')]: {
        // maxHeight: '100px'
        minHeight: '120px',
        // padding: '1px'
      },
    },

    iconLocation: {
      color: '#FCC419',
    },

    blueBox: {
      textAlign: 'center',
      padding: theme.spacing.xl,
      borderRadius: theme.radius.xl,
      border: '2px solid #0035b7',
      cursor: 'pointer',
    },

    goldButton: {
      backgroundColor: '#0034b0',
      color: 'white'
    }

  }));
  const { classes } = useStyles();
  const autoplay = useRef(Autoplay({ delay: 2200 }));

  return (
    <>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/hairremoval-banner.png"
      >
        <Container mx="auto" className={classes.section6} >
          <Flex
            gap="xl"
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/hairremoval-icon1.png" alt="Random image" />
            <Image maw="2.125rem" src="https://sozoskinclinic.com/assets/img/hairremoval-icon2.png" alt="Random image" />
          </Flex>
          <Image
            maw={'8rem'}
            src={logoSozoWhite}
            pt="20px"
          />
          <Title className={classes.title} c="white" pt="20px">
            Hair Removal
          </Title>

          <Divider size="md" mt="1rem" maw={100} />
          <Space h="md" />
          <Text c="gray.0" fw={700}>Permanen Hilangkan Bulu Halus pada Tubuhmu</Text>
        </Container>
      </BackgroundImage>

      <Box mx="auto" pt="1rem" pb="1rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div><Text c="#0035b7" ta="left" fz="md" style={{ fontSize: '20px' }}>Dapatkan kulit mulus bebas rambut dengan Permanent Hair Removal Treatment. Treatment dilakukan menggunakan teknologi Intense Pulse Light (IPL) untuk menghancurkan akar rambut di berbagai area tubuh sehingga rambut akan tumbuh melambat hingga akhirnya menghilang. Efek penghilangan rambut dapat bertahan lama hingga permanen.</Text></div>
          </SimpleGrid>
        </Container>
      </Box>
      <Box mx="auto" pt="1rem" pb="1rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <div>
              <Title className={classes.title}>
                Pilih Jenis Hair Removal
              </Title>
            </div>
            <div>
              <Box mx="auto" className={classes.blueBox} mt="2rem" mb="2rem">
                <Grid>
                  <Grid.Col lg={6}>
                    <Title className={classes.title} ta="left">
                      Underarm
                    </Title>
                    <Text c="#0035b7" ta="left">Underam hair removal adalah treatment dengan teknologi IPL yang dapat menghancurkan rambut ketiak sampai ke akar. Rambut akan tumbuh melambat
                      sampai akhirnya menghilang secara permanen. Ketiak jadi lebih cerah dan bebas rambut.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='1rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/hairremoval-asset2.png" alt="Random image" />
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
            <div>
              <Box mx="auto" className={classes.blueBox} mt="2rem" mb="2rem">
                <Grid >
                  <Grid.Col span={12}>
                    <Title className={classes.title} ta="center">
                      Bikini Line / Brazilian V / Brazilian VI
                    </Title>
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Image mx="auto" radius="md" src="https://sozoskinclinic.com/assets/img/hariremoval-perawatan2.png" alt="Random image" />
                  </Grid.Col>
                  <Grid.Col lg={6}>
                    <Text c="#0035b7" ta="left">Miliki daerah kewanitaan yang bersih, terawat, dan bebas rambut dengan pilihan treatment Bikini Line, Brazilian V, dan Brazilian VI dari SOZO Clinic. Mengggunakan teknologi IPL yang dapat menghancurkan akar rambut serta dilakukan oleh dokter yang berpengalaman, treatment ini sudah pasti aman, nyaman, dan tanpa rasa sakit.</Text>
                    <Flex
                      gap="xl"
                      justify="flex-start"
                      align="flex-start"
                      direction="row"
                      wrap="wrap"
                      mt='1rem'
                    >
                      <Image maw="8.125rem" src="https://sozoskinclinic.com/assets/img/img-durasi30.png" alt="Random image" />
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>
      </Box>
      <Box mx="auto" pt="1rem" pb="1rem" mt="2rem" mb="2rem">
        <Container mt="1rem">
          <SimpleGrid cols={1}>
            <Title className={classes.title} ta="center">
              Tahapan Hair Removal Treatment di SOZO Clinic
            </Title>
            <Box mx="auto" ta="center" mt="1rem" mb="1rem">
              <Grid justify="center" align="center">
                <Grid.Col span={6}>
                  <Box style={{ minHeight: '185px', backgroundColor: '#0035b7', borderRadius: '40px', padding: '20px', margin: 'auto', maxWidth: '250px' }}>
                    <Text c="white" ta="center">Pasien diminta untuk mencukur rambut halus pada area yang akan dirawat 2-3 hari sebelum treatment.</Text>
                  </Box>

                </Grid.Col>
                <Grid.Col span={6}>
                  <Box style={{ minHeight: '185px', backgroundColor: '#0035b7', borderRadius: '40px', padding: '20px', margin: 'auto', maxWidth: '250px' }}>
                    <Text c="white" ta="center">Pemberian gel oleh terapis pada area yang akan dilakukan perawatan untuk melindungi kulit dari rasa panas saat proses penyinaran.</Text>
                  </Box>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Box style={{ minHeight: '185px', backgroundColor: '#0035b7', borderRadius: '40px', padding: '20px', margin: 'auto', maxWidth: '250px' }}>
                    <Text c="white" ta="center">Proses penyinaran dimulai dan memakan waktu selama 15-40 menit tergantung luas area yang dirawat. Sensasi selama perawatan hair removal dapat berbeda setiap pasien, tergantung pada kondisi kulit masing-masing.</Text>
                  </Box>
                </Grid.Col>
                <Grid.Col span={6} >
                  <Box style={{ minHeight: '185px', backgroundColor: '#0035b7', borderRadius: '40px', padding: '20px', margin: 'auto', maxWidth: '250px' }}>
                    <Text c="white" ta="center">Setelah proses penyinaran selesai, terapis akan membersihkan gel dari kulit pasien.</Text>
                  </Box>
                </Grid.Col>
              </Grid>
            </Box>
          </SimpleGrid>
        </Container>
      </Box >
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/background-blueleft.png"
      >
        <Box mx="auto" pt="1rem" pb="1rem" mt="2rem" mb="2rem">
          <Container pt="1rem" pb="1rem" >
            <SimpleGrid cols={1}>
              <div>
                <Box mx="auto">
                  <Grid align="center">
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <Title className={classes.title} c="white" style={{ fontSize: '4rem;' }}>
                          Mengapa <br></br>Memilih <br></br>Treatment <br></br>Laser Hair <br></br>Removal di <br></br>SOZO Clinic?
                        </Title>
                      </Box>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Box mx="10px">
                        <List ta="left" c="#0035b7" style={{ fontSize: '20px' }}>
                          <List.Item>Menggunakan teknologi I2PL berbasis cahaya yang menghancurkan rambut dari akar sehingga tidak tumbuh lagi.</List.Item>
                          <List.Item>Dilakukan oleh terapis profesional, nyaman, dan tanpa rasa sakit berlebih.</List.Item>
                          <List.Item>Hasil efektif dapat terlihat langsung dalam 1x treatment.</List.Item>
                          <List.Item>Kulit halus mulus bebas bulu yang tahan lama hingga bersih permanen.</List.Item>
                        </List>
                      </Box>
                    </Grid.Col>
                  </Grid>
                </Box>
              </div>
            </SimpleGrid>
          </Container>
        </Box>
      </BackgroundImage>
      <Box mx="auto" pt="1rem" pb="1rem" mt="2rem" mb="2rem">
        <Container>
          <SimpleGrid cols={1}>
            <div>
              <Title className={classes.title}>
                Tips Sebelum dan Sesudah <br></br>Hair Removal Treatment
              </Title>
            </div>
            <div>
              <Box mx="auto" ta="center" mt="1rem" mb="1rem">
                <Grid justify="center" >
                  <Grid.Col span={6} style={{ maxWidth: '400px' }}>
                    <Table withBorder withColumnBorders className={classes.blueBox} >
                      <thead>
                        <tr>
                          <th style={{ backgroundColor: '#3483df' }}><Title order={3} ta="center" c="gray.0">Sebelum Treatment</Title></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><Text c="#0035b7" ta="left">Jangan melakukan waxing, pencabutan rambut
                            secara manual, atau dengan krim perontok 2
                            minggu sebelum perawatan</Text></td>
                        </tr>
                        <tr>
                          <td><Text c="#0035b7" ta="left">Cukur rambut pada area yang akan dilakukan
                            perawatan 2 hari sebelumnya</Text></td>
                        </tr>
                        <tr>
                          <td><Text c="#0035b7" ta="left">Pastikan area yang akan dilakukan perawatan
                            tidak kering dan iritasi</Text></td>
                        </tr>
                        <tr>
                          <td><Text c="#0035b7" ta="left">Hindari paparan sinar matahari langsung selama 2
                            minggu sebelum perawatan</Text></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Grid.Col>
                  <Grid.Col span={6} style={{ maxWidth: '400px' }}>

                    <Table withBorder withColumnBorders className={classes.blueBox}>
                      <thead>
                        <tr>
                          <th style={{ backgroundColor: '#0035b7' }}><Title order={3} ta="center" c="gray.0">Sesudah Treatment</Title></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><Text c="#0035b7" ta="left">Jangan gunakan deodorant, scrub, atau skincare
                            lainnya selama 48 jam setelah perawatan</Text></td>
                        </tr>
                        <tr>
                          <td><Text c="#0035b7" ta="left">Hindari olahraga berat, sauna, berenang, berjemur,
                            dan mandi menggunakan air panas 48 setelah
                            perawatan</Text></td>
                        </tr>
                        <tr>
                          <td><Text c="#0035b7" ta="left">Jadwalkan treatment selanjutnya dalam 3-4
                            minggu setelah treatment sebelumnya</Text></td>
                        </tr>
                        <tr>
                          <td><Text c="#0035b7" ta="left">Cukur rambut yang sedang dalam proses rontok
                            48 jam setelah perawatan</Text></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Grid.Col>
                </Grid>
              </Box>
            </div>
          </SimpleGrid>
        </Container>
      </Box>
      <Container pt="1rem" pb="1rem" mt="2rem" mb="2rem">
        <SimpleGrid cols={1}>
          <div>
            <Title className={classes.title}>
              Before and After <br></br>Hair Removal Treatment
            </Title>
          </div>
          <div>
            <Box mx="auto" ta="center" mt="1rem" mb="1rem">
              <Grid grow gutter="xs">
                <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                  <Box mx="10px">
                    <Image
                      radius="md"
                      src="https://sozoskinclinic.com/assets/img/hairremoval-asset3.png"
                      style={{ maxWidth: '350px', margin: 'auto' }}
                    />
                  </Box>
                </Grid.Col>
                <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                  <Box mx="10px">
                    <Image
                      radius="md"
                      src="https://sozoskinclinic.com/assets/img/hairremoval-asset4.png"
                      style={{ maxWidth: '350px', margin: 'auto' }}
                    />
                  </Box>
                </Grid.Col>
              </Grid>
            </Box>
          </div>
        </SimpleGrid>
      </Container>
      <BackgroundImage
        src="https://sozoskinclinic.com/assets/img/footer.png"
      >
        <Box mx="auto" mt="2rem" c="#d4f4f4" className={classes.section6} >
          <Container>
            <Grid grow gutter="xs">
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Text ta="left" c="gray.0" style={{ fontSize: '25px' }}>  Konsultasikan permasalahan <br></br>wajah dan kulitmu secara  <br></br>gratis bersama beauty  <br></br>consultant kami untuk  <br></br>mendapatkan rekomendasi  <br></br>treatment terbaik untukmu. </Text>
              </Grid.Col>
              <Grid.Col xs={2} sm={2} md={2} lg={2} className={classes.gridMobileLocation}>
                <Box mx="10px">

                </Box>
              </Grid.Col>
            </Grid>
          </Container>
        </Box>
      </BackgroundImage>
      <Container mt="4rem" mb="4rem" ta="center">
        <SimpleGrid cols={1}>
          <div><Anchor href="https://api.whatsapp.com/send/?phone=6285219228545&text=Halo+Sozo%2C+Saya+dari+website+dan+tertarik+untuk+konsultasi+permasalahan+kulit&type=phone_number&app_absent=0" target="_blank"><Button mx="auto" mt="xl" radius="xl" size="lg" className={classes.goldButton}>
            <Title order={2}>Konsultasi sekarang</Title>
          </Button></Anchor></div>
        </SimpleGrid>
      </Container>
      <CustomFonts />
    </>
  );
};

export default HairRemoval;

